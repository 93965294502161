import {
  ADMINISTRATION_ORGANIZATION_LIST_REQUESTED,
  ADMINISTRATION_ORGANIZATION_LIST_PROCESSING,
  ADMINISTRATION_ORGANIZATION_LIST_SUCCESSED,
  ADMINISTRATION_ORGANIZATION_LIST_FAILED,
  ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_LIST_PROCESSING,
  ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSETS_GROUP_LIST_FAILED,
  ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED,
  ADMINISTRATION_CLASSIFICATION_LIST_FAILED,
  ADMINISTRATION_CLASSIFICATION_LIST_PROCESSING,
  ADMINISTRATION_OWNERS_LIST_REQUESTED,
  ADMINISTRATION_OWNERS_LIST_PROCESSING,
  ADMINISTRATION_OWNERS_LIST_SUCCESSED,
  ADMINISTRATION_OWNERS_LIST_FAILED,
  ADMINISTRATION_SET_SELECTED_ORGANIZATION_SUCCESSED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_PROCESSING,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_SUCCESSED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED,
  ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_USERS_PROCESSING,
  ADMINISTRATION_ORAGANIZATION_USERS_SUCCESSED,
  ADMINISTRATION_ORAGANIZATION_USERS_FAILED,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_PROCESSING,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_SUCCESSED,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_FAILED,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_PROCESSING,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_SUCCESSED,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_FAILED,
  ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED,
  ADMINISTRATION_CREATE_ASSETS_GROUP_PROCESSING,
  ADMINISTRATION_CREATE_ASSETS_GROUP_SUCCESSED,
  ADMINISTRATION_CREATE_ASSETS_GROUP_FAILED,
  ADMINISTRATION_WORKFLOW_LIST_REQUESTED,
  ADMINISTRATION_WORKFLOW_LIST_PROCESSING,
  ADMINISTRATION_WORKFLOW_LIST_SUCCESSED,
  ADMINISTRATION_WORKFLOW_LIST_FAILED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_FAILED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_SUCCESSED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_PROCESSING,
  ORAGANIZATION_REQUESTED,
  ORAGANIZATION_PROCESSING,
  ORAGANIZATION_SUCCESSED,
  ORAGANIZATION_FAILED,
  ADMINISTRATION_CATEGORY_LIST_PROCESSING,
  ADMINISTRATION_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CATEGORY_LIST_FAILED,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_PROCESSING,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_PROCESSING,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_CATEGORY_PROCESSING,
  ADMINISTRATION_WORKFLOW_CATEGORY_SUCCESSED,
  ADMINISTRATION_WORKFLOW_CATEGORY_FAILED,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_PROCESSING,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_SUCCESSED,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_FAILED,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_PROCESSING,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_SUCCESSED,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_FAILED,

  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_PROCESSING,
  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_SUCCESSED,
  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_FAILED,

  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_PROCESSING,
  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_SUCCESSED,
  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_FAILED,

  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_PROCESSING,
  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_SUCCESSED,
  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_FAILED,

  ADMINISTRATION_WORKFLOW_TAGS_REQUESTED,
  ADMINISTRATION_WORKFLOW_TAGS_PROCESSING,
  ADMINISTRATION_WORKFLOW_TAGS_SUCCESSED,
  ADMINISTRATION_WORKFLOW_TAGS_FAILED,
  ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED,
  ADMINISTRATION_ASSET_VALUE_LIST_PROCESSING,
  ADMINISTRATION_ASSET_VALUE_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_VALUE_LIST_FAILED,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_PROCESSING,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_PROCESSING,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_PROCESSING,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_PROCESSING,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_FAILED,
  ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_TIMEZONE_PROCESSING,
  ADMINISTRATION_ORAGANIZATION_TIMEZONE_SUCCESSED,
  ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
  ADMINISTRATION_AUTOMATION_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_SUCCESSED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_PROCESSING,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED,
  ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_LIST_PROCESSING,
  ADMINISTRATION_CASES_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_CATEGORY_LIST_FAILED,
  ADMINISTRATION_CASES_CATEGORY_DISPOSITION_SUCCESSED,
  ADMINISTRATION_CASES_CATEGORY_DISPOSITION_FAILED,
  ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_PROCESSING,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_FAILED,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_PROCESSING,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED,
  ADMINISTRATION_ASSET_TYPE_LIST_PROCESSING,
  ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_TYPE_LIST_FAILED,
  ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_PRIMARY_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_PRIMARY_CATEGORY_LIST_FAILED,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_PROCESSING,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_FAILED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_PROCESSING,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_PROCESSING,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_PROCESSING,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_PROCESSING,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_PROCESSING,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_PROCESSING,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_SUCCESSED,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_FAILED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_FAILED,
  ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED,
  ADMINISTRATION_CASES_LOCATIONS_LIST_PROCESSING,
  ADMINISTRATION_CASES_LOCATIONS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_LOCATIONS_LIST_FAILED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED,
  ADMINISTRATION_VULNERABILITY_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_FAILED,
  ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED,
  ADMINISTRATION_VULNERABILITY_SEVERITY_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED,
  ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED,
  ADMINISTRATION_VULNERABILITY_THREAT_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_THREAT_FAILED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SEVERITY_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_SEVERITY_FAILED,
  ADMINISTRATION_CASES_NCISS_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_LIST_PROCESSING,
  ADMINISTRATION_CASES_NCISS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_PROCESSING,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_FAILED,
  ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED,
  ADMINISTRATION_GET_DISPOSITION_LIST_PROCESSING,
  ADMINISTRATION_GET_DISPOSITION_LIST_SUCCESSED,
  ADMINISTRATION_GET_DISPOSITION_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_PROCESSING,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_FAILED,
  INGESTION_TYPE_REQUESTED,
  INGESTION_TYPE_SUCCESSED,
  INGESTION_TYPE_FAILED,
  INGESTION_WIDGET_REQUESTED,
  INGESTION_WIDGET_SUCCESSED,
  INGESTION_WIDGET_FAILED,
  INGESTION_FREQUENCY_REQUESTED,
  INGESTION_FREQUENCY_SUCCESSED,
  INGESTION_FREQUENCY_FAILED,
  INGESTION_APPLICATION_REQUESTED,
  INGESTION_APPLICATION_SUCCESSED,
  INGESTION_APPLICATION_FAILED,
  INGESTION_USERS_REQUESTED,
  INGESTION_USERS_SUCCESSED,
  INGESTION_USERS_FAILED,
  INGESTION_ACTION_REQUESTED,
  INGESTION_ACTION_SUCCESSED,
  INGESTION_ACTION_FAILED,
  ACTIONS_TYPE_REQUESTED,
  ACTIONS_TYPE_SUCCESSED,
  ACTIONS_TYPE_FAILED,
  ACTIONS_SCRIPT_TYPE_REQUESTED,
  ACTIONS_SCRIPT_TYPE_SUCCESSED,
  ACTIONS_SCRIPT_TYPE_FAILED,
  ACTIONS_IO_TYPE_REQUESTED,
  ACTIONS_IO_TYPE_SUCCESSED,
  ACTIONS_IO_TYPE_FAILED,
  ACTIONS_APPLICATION_REQUESTED,
  ACTIONS_APPLICATION_SUCCESSED,
  ACTIONS_APPLICATION_FAILED,
  GET_INGESTION_MULTI_DATA_REQUESTED,
  GET_INGESTION_MULTI_DATA_SUCCESSED,
  GET_INGESTION_MULTI_DATA_FAILED,
  GET_INGESTION_MAPPING_REQUESTED,
  GET_INGESTION_MAPPING_SUCCESSED,
  GET_INGESTION_MAPPING_FAILED,
  ADMINISTRATION_LOGS_LIST_SUCCESSED,
  ADMINISTRATION_LOGS_LIST_FAILED,
  ADMINISTRATION_LOGS_APPS_LIST_REQUESTED,
  APP_LOGS_NOTIFICATION_LIST_REQUESTED,
  APP_LOGS_NOTIFICATION_LIST_SUCCESSED,
  APP_LOGS_NOTIFICATION_LIST_FAILED,
  ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED,
  ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED,
  ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED,
  ADMINISTRATION_PRODUCTS_LIST_SUCCESSED,
  ADMINISTRATION_PRODUCTS_LIST_FAILED,
  ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_LIST_FAILED,
  ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED,
  ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_SUCCESSED,
  ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_FAILED,
  ADMINISTRATION_ACCESS_PERMISSION_REQUESTED,
  ADMINISTRATION_ACCESS_PERMISSION_SUCCESSED,
  ADMINISTRATION_ACCESS_PERMISSION_FAILED,
  ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED,
  ADMINISTRATION_ACCESS_DASHBOARDS_SUCCESSED,
  ADMINISTRATION_ACCESS_DASHBOARDS_FAILED,
  ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED,
  ADMINISTRATION_ACCESS_LANDING_PAGE_SUCCESSED,
  ADMINISTRATION_ACCESS_LANDING_PAGE_FAILED,
  ADMINISTRATION_ACCESS_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_GROUP_SUCCESSED,
  ADMINISTRATION_ACCESS_GROUP_FAILED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_SUCCESSED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_FAILED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUPS_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_GROUPS_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLES_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_ROLES_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_FAILED,
  ADMINISTRATION_NOTIFICATIONS_LIST_SUCCESSED,
  ADMINISTRATION_NOTIFICATIONS_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED,
  ADMINISTRATION_FIELD_SETTING_LIST_SUCCESSED,
  ADMINISTRATION_FIELD_SETTING_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_PROCESSING,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_FAILED,
  ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_SUCCESSED,
  ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_FAILED,
  ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED,
  ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_SUCCESSED,
  ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_FAILED,
  ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED,
  ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED,
  ADMINISTRATION_GET_EMAIL_CONNECTIVITY_FAILED,
  ADMINISTRATION_GET_EMAIL_CONNECTIVITY_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_FAILED,

  ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_FAILED,

  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED,
  ADMINISTRATION_CASE_FIELD_SETTING_LIST_SUCCESSED,
  ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED,
  ASSET_CLASSIFICATION_GET_FORMULA_SUCCESSED,
  ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_LIST_SUCCESSED,
  ASSET_CLASSIFICATION_INTEGIRTY_LIST_FAILED,
  ASSET_CLASSIFICATION_AVAILABILITY_LIST_SUCCESSED,
  ASSET_CLASSIFICATION_AVAILABILITY_LIST_FAILED,
  ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED,
  ADMINISTRATION_GET_TABS_HEADING_SUCCESSED,
  ADMINISTRATION_GET_TABS_HEADING_FAILED,
  ADMINISTRATION_CASES_SLA_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_LIST_PROCESSING,
  ADMINISTRATION_CASES_SLA_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SLA_LIST_FAILED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_SUCCESSED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_FAILED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_SUCCESSED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_FAILED,
  ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_SUCCESSED,
  ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED,
  ADMINISTRATION_RISK_MATRIX_LIST_SUCCESSED,
  ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED,
  ADMINISTRATION_RISK_MATRIX_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED,
  ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_SUCCESSED,
  ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_FAILED,
  ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED,
  ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_REQUESTED,
  ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_SUCCESSED,
  ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_FAILED,
  AUTOMATION_ACTION_CONFIGURATION_REQUESTED,
  AUTOMATION_ACTION_CONFIGURATION_SUCCESSED,
  AUTOMATION_ACTION_CONFIGURATION_FAILED,
  AUTOMATION_ACTION_VIEW_SCRIPT_SUCCESSED,
  AUTOMATION_ACTION_VIEW_SCRIPT_FAILED,
  AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED,
  ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINER_LIST_SUCCESS,
  ADMINISTRATION_CASES_CONTAINER_LIST_FAILDED,
  ADMINISTRATION_CASES_DESPOSITION_SUCCESS,
  ADMINISTRATION_CASES_DESPOSITION_FAILED,
  ADMINISTRATION_CASES_CATEGORY_SUCCESS,
  ADMINISTRATION_CASES_CATEGORY_FAILED,
  ADMINISTRATION_VAL_TECHNICAL_SERVERITY_LIST_REQUESTED,
  ADMINISTRATION_VAL_TECHNICAL_SERVERITY_LIST_SUCCESSED,
  ADMINISTRATION_VAL_TECHNICAL_SERVERITY_LIST_FAILED,
  ADMINISTRATION_NO_TECHNICAL_VAL_SERVERITY_LIST_REQUESTED,
  ADMINISTRATION_NO_TECHNICAL_VAL_SERVERITY_LIST_SUCCESSED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_FAILED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_FAILED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_VIEW_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SLA_VIEW_LIST_FAILED,
  ADMINISTRATION_CASES_SLA_RULE_LIST_FAILED,
  ADMINISTRATION_CASES_SLA_RULE_LIST_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_CREATE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_CREATE_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_CREATE_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_SOURCES_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_SOURCES_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_FAILED,
  ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_PROCESSING,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_PROCESSING,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_FAILED,

  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_PROCESSING,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_FAILED,

  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_FAILED,
  ADMINISTRATION_NOTIFICATIONS_LOG_LIST_SUCCESSED,
  ADMINISTRATION_NOTIFICATIONS_LOG_LIST_FAILED,
  ADMINISTRATION_CASES_LESSON_LIST_REQUESTED,
  ADMINISTRATION_CASES_LESSON_LIST_PROCESSING,
  ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED,
  ADMINISTRATION_CASES_LESSON_LIST_FAILED,

  ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_LIST_PROCESSING,
  ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED,

  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_PROCESSING,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED,
  ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_LIST_SUCCESSED,
  ADMINISTRATION_AFFECTED_VENDOR_LIST_FAILED,
  ADMINISTRATION_AFFECTED_PRODUCT_LIST_SUCCESSED,
  ADMINISTRATION_AFFECTED_PRODUCT_LIST_FAILED,
  ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED,
  SET_ALERT_COUNT,
  PRE_INGESTION_RULE_SUCCESSED,
  PRE_INGESTION_RULE_FAILED,
  PRE_INGESTION_RULE_REQUESTED,
  ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED,
} from '../../constants/actionTypes';

const initialState = {
  organization: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  allOrganizationsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  organizationsInformation: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: {},
    timezonList: {},
  },
  organizationMembers: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  selectedOraganization: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },

  cases: {
    containerList: {},
    categoryList: {},
    dispositionList: {},
    hasError: false,
    isSucess: false,
    requested: false
  },
  assets: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetsFormula: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    defautlValue: {},
  },
  assetsConfidentiality: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    confidentialityData: [],
  },
  assetsIntegrity: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    integrityList: [],
  },
  assetsAvailability: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    availabilityList: [],
  },
  owners: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  workflow: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  organizationsUsers: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: {},
  },
  assetsSourceListData: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetsSubGroupData: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetsCreate: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  workflowCategory: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
    categoryData: [],
    subCategoryData: [],
    taskCategoryData: [],
  },
  assetsSubGroupTabData: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetsSubGroupDropDownData: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetsValueList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetOsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  riskManagement: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
    valueList: [],
    vulnerabilityList: [],
    probablity: [],
    selectedFormula: null,
    probablitySelected: '',
  },
  automation: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
    validationList: [],
    typeList: [],
    viewSciptData: [],
    widgetList: [],
    frequencyList: [],
    applicationList: [],
    usersList: [],
    actionList: [],
    actionOutputList: {},
    actionsTypeList: [],
    actionsScriptList: [],
    actionsIOTypeList: [],
    actionsApplicationList: [],
    mappingDropDownData: [],
    mappingData: [],
    appConfigurationData: {},
    preIngestionRuleList: [],
  },
  integrationScript: {
    isProcessing: false,
    script: "",
  },
  assetDepartmentList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  casesCategoriesList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  categorydispositionList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  casesSubCategoriesList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  businessGroupList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetTypeList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  primarySubCategoryList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  dispositionsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  subDispositionsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  vulnerabilityList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
    severityList: [],
    severityLevel: [],
    tecnicalServerityLevel: [],
    nontecnicalServerityLevel: [],
    threatList: [],
  },
  assetDropDownList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetSubGroupDropDownList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetOwnerDropDownList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetDepartmentDropDownList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  assetValueDropDownList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  subDispositionsDropdownList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  ditectionMethodList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  slaList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  ncissList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  casesNcissDropDownList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  ncisscategoriesList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  perioritiesList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  locationsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  threatIntellList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
    categoryList: [],
    severityList: [],
    affectedVendorList: [],
    affectedProductList: [],
  },
  logsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  appLogsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  productsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  accessControlList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
    authenticateList: [],
    permissionList: [],
    landingList: [],
    groupList: [],
    sessionPassword: [],
    accessGroups: [],
    roles: [],
    actions: [],
    actionGroup: [],
    actionGroupTab: [],
    authSetting: [],
    authenticationTypeList: [],
    authencticationVendor: [],
    previleges: [],
    previlegesGroup: []
  },
  productSettingList: {
    requested: false,
    isSuccess: null,
    loading: false,
    hasErrors: null,
    feedback: [],
    failedRegistrations: [],
    widgetList: [],
    widgetSources: [],
    widgetGroups: [],
    licenseInfo: [],
    licenseSources: [],
    backupList: [],
    notifyUser: [],
    healthData: null
  },
  selectedBackupSetting: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  selectedSftpConfigure: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  notificationList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },

  fieldSettingsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  caseFieldSettingsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  allTabsHeading: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: {
      assetsBussinessGroup: '',
      assetsType: '',
      assetsValue: '',
      assetsClassification: '',
      riskmanagementProbability: {},
      businessImpact: {},
      riskScore: ''
    },
  },
  riskMatrixRngeFrom: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  riskMatrixRngeTo: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  riskMatrixColorList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },

  riskMatrixList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },

  slaLogViewList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: null,
  },

  slaTRuleViewList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  riskMetaList: {
    requested: null,
    isSuccess: null,
    hasErrors: null,
    listData: null,
  },
  logNotificationList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  lessonsList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  containedByList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  containmentStatusList: {
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
    listData: [],
  },
  alertCount: 0
};

export const administrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMINISTRATION_ORGANIZATION_LIST_REQUESTED: {
      return {
        ...state,
        organization: {
          ...state.organization,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ORGANIZATION_LIST_PROCESSING: {
      return {
        ...state,
        organization: {
          ...state.organization,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ORGANIZATION_LIST_SUCCESSED: {
      return {
        ...state,
        organization: {
          ...state.organization,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ORGANIZATION_LIST_FAILED: {
      return {
        ...state,
        organization: {
          ...state.organization,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED: {
      return {
        ...state,
        assetsConfidentiality: {
          ...state.assetsConfidentiality,
          confidentialityData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_SUCCESSED: {
      return {
        ...state,
        assetsConfidentiality: {
          ...state.assetsConfidentiality,
          confidentialityData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED: {
      return {
        ...state,
        assetsConfidentiality: {
          ...state.assetsConfidentiality,
          confidentialityData: [],
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED: {
      return {
        ...state,
        assetsIntegrity: {
          ...state.assetsIntegrity,
          integrityList: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ASSET_CLASSIFICATION_INTEGIRTY_LIST_SUCCESSED: {
      return {
        ...state,
        assetsIntegrity: {
          ...state.assetsIntegrity,
          integrityList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ASSET_CLASSIFICATION_INTEGIRTY_LIST_FAILED: {
      return {
        ...state,
        assetsIntegrity: {
          ...state.assetsIntegrity,
          integrityList: [],
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED: {
      return {
        ...state,
        assetsAvailability: {
          ...state.assetsAvailability,
          availabilityList: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ASSET_CLASSIFICATION_AVAILABILITY_LIST_SUCCESSED: {
      return {
        ...state,
        assetsAvailability: {
          ...state.assetsAvailability,
          availabilityList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ASSET_CLASSIFICATION_AVAILABILITY_LIST_FAILED: {
      return {
        ...state,
        assetsAvailability: {
          ...state.assetsAvailability,
          availabilityList: [],
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CLASSIFICATION_LIST_PROCESSING: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CLASSIFICATION_LIST_FAILED: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED: {
      return {
        ...state,
        assetsFormula: {
          ...state.assetsFormula,
          defautlValue: {},
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ASSET_CLASSIFICATION_GET_FORMULA_SUCCESSED: {
      return {
        ...state,
        assetsFormula: {
          ...state.assetsFormula,
          defautlValue: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CLASSIFICATION_LIST_FAILED: {
      return {
        ...state,
        assetsFormula: {
          ...state.assetsFormula,
          defautlValue: {},
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_OWNERS_LIST_REQUESTED: {
      return {
        ...state,
        owners: {
          ...state.owners,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_OWNERS_LIST_PROCESSING: {
      return {
        ...state,
        owners: {
          ...state.owners,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_OWNERS_LIST_SUCCESSED: {
      return {
        ...state,
        owners: {
          ...state.owners,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_OWNERS_LIST_FAILED: {
      return {
        ...state,
        owners: {
          ...state.owners,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_LIST_PROCESSING: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_LIST_FAILED: {
      return {
        ...state,
        assets: {
          ...state.assets,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED: {
      return {
        ...state,
        selectedOraganization: {
          ...state.selectedOraganization,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_SELECTED_ORGANIZATION_PROCESSING: {
      return {
        ...state,
        selectedOraganization: {
          ...state.selectedOraganization,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_SELECTED_ORGANIZATION_SUCCESSED: {
      return {
        ...state,
        selectedOraganization: {
          ...state.selectedOraganization,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED: {
      return {
        ...state,
        selectedOraganization: {
          ...state.selectedOraganization,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED: {
      return {
        ...state,
        organizationsUsers: {
          ...state.organizationsUsers,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_USERS_PROCESSING: {
      return {
        ...state,
        organizationsUsers: {
          ...state.organizationsUsers,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_USERS_SUCCESSED: {
      return {
        ...state,
        organizationsUsers: {
          ...state.organizationsUsers,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_USERS_FAILED: {
      return {
        ...state,
        organizationsUsers: {
          ...state.organizationsUsers,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED: {
      return {
        ...state,
        assetsSourceListData: {
          ...state.assetsSourceListData,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_PROCESSING: {
      return {
        ...state,
        assetsSourceListData: {
          ...state.assetsSourceListData,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_SUCCESSED: {
      return {
        ...state,
        assetsSourceListData: {
          ...state.assetsSourceListData,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_FAILED: {
      return {
        ...state,
        assetsSourceListData: {
          ...state.assetsSourceListData,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED: {
      return {
        ...state,
        assetsSubGroupData: {
          ...state.assetsSubGroupData,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_PROCESSING: {
      return {
        ...state,
        assetsSubGroupData: {
          ...state.assetsSubGroupData,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_SUCCESSED: {
      return {
        ...state,
        assetsSubGroupData: {
          ...state.assetsSubGroupData,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_FAILED: {
      return {
        ...state,
        assetsSubGroupData: {
          ...state.assetsSubGroupData,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED: {
      return {
        ...state,
        assetsCreate: {
          ...state.assetsCreate,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CREATE_ASSETS_GROUP_PROCESSING: {
      return {
        ...state,
        assetsCreate: {
          ...state.assetsCreate,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CREATE_ASSETS_GROUP_SUCCESSED: {
      return {
        ...state,
        assetsCreate: {
          ...state.assetsCreate,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CREATE_ASSETS_GROUP_FAILED: {
      return {
        ...state,
        assetsCreate: {
          ...state.assetsCreate,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_WORKFLOW_LIST_REQUESTED: {
      return {
        ...state,
        workflow: {
          ...state.workflow,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_LIST_PROCESSING: {
      return {
        ...state,
        workflow: {
          ...state.workflow,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_LIST_SUCCESSED: {
      return {
        ...state,
        workflow: {
          ...state.workflow,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_LIST_FAILED: {
      return {
        ...state,
        workflow: {
          ...state.workflow,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CATEGORY_LIST_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CATEGORY_LIST_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CATEGORY_LIST_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED: {
      return {
        ...state,
        assetsSubGroupTabData: {
          ...state.assetsSubGroupTabData,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSET_SUB_GROUP_LIST_PROCESSING: {
      return {
        ...state,
        assetsSubGroupTabData: {
          ...state.assetsSubGroupTabData,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_SUB_GROUP_LIST_SUCCESSED: {
      return {
        ...state,
        assetsSubGroupTabData: {
          ...state.assetsSubGroupTabData,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_SUB_GROUP_LIST_FAILED: {
      return {
        ...state,
        assetsSubGroupTabData: {
          ...state.assetsSubGroupTabData,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED: {
      return {
        ...state,
        assetsSubGroupDropDownData: {
          ...state.assetsSubGroupDropDownData,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_PROCESSING: {
      return {
        ...state,
        assetsSubGroupDropDownData: {
          ...state.assetsSubGroupDropDownData,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_SUCCESSED: {
      return {
        ...state,
        assetsSubGroupDropDownData: {
          ...state.assetsSubGroupDropDownData,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_FAILED: {
      return {
        ...state,
        assetsSubGroupDropDownData: {
          ...state.assetsSubGroupDropDownData,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_MEMBERS_PROCESSING: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_MEMBERS_SUCCESSED: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_MEMBERS_FAILED: {
      return {
        ...state,
        organizationMembers: {
          ...state.organizationMembers,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ORAGANIZATION_REQUESTED: {
      return {
        ...state,
        allOrganizationsList: {
          ...state.allOrganizationsList,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          taskCategoryData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_TASK_CATEGORY_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          taskCategoryData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_TASK_CATEGORY_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          taskCategoryData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_TASK_CATEGORY_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          taskCategoryData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTacticsData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_TACTICS_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTacticsData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_TACTICS_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTacticsData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_TACTICS_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTacticsData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }


    case ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTechniquesData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTechniquesData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTechniquesData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreTechniquesData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }


    case ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreSubTechniquesData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreSubTechniquesData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreSubTechniquesData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          mitreSubTechniquesData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_WORKFLOW_TAGS_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          tagsData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_TAGS_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          tagsData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_TAGS_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          tagsData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_TAGS_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          tagsData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          categoryData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_CATEGORY_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          categoryData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_CATEGORY_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          categoryData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_CATEGORY_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          categoryData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          subCategoryData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ORAGANIZATION_PROCESSING: {
      return {
        ...state,
        allOrganizationsList: {
          ...state.allOrganizationsList,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_SUB_CATEGORY_PROCESSING: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          subCategoryData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ORAGANIZATION_SUCCESSED: {
      return {
        ...state,
        allOrganizationsList: {
          ...state.allOrganizationsList,
          listData: action.data,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_SUB_CATEGORY_SUCCESSED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          subCategoryData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ORAGANIZATION_FAILED: {
      return {
        ...state,
        allOrganizationsList: {
          ...state.allOrganizationsList,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_WORKFLOW_SUB_CATEGORY_FAILED: {
      return {
        ...state,
        workflowCategory: {
          ...state.workflowCategory,
          subCategoryData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED: {
      return {
        ...state,
        assetsValueList: {
          ...state.assetsValueList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSET_VALUE_LIST_PROCESSING: {
      return {
        ...state,
        assetsValueList: {
          ...state.assetsValueList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_VALUE_LIST_SUCCESSED: {
      return {
        ...state,
        assetsValueList: {
          ...state.assetsValueList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_VALUE_LIST_FAILED: {
      return {
        ...state,
        assetsValueList: {
          ...state.assetsValueList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED: {
      return {
        ...state,
        assetOsList: {
          ...state.assetOsList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_PROCESSING: {
      return {
        ...state,
        assetOsList: {
          ...state.assetOsList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED: {
      return {
        ...state,
        assetOsList: {
          ...state.assetOsList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_FAILED: {
      return {
        ...state,
        assetOsList: {
          ...state.assetOsList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_PROCESSING: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          selectedFormula: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }


    case ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_FAILED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: true,
          requested: true,
        },
      };
    }


    case ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }


    case ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }


    case ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }



    case ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_FAILED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: [],
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }




    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }


    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          probablity: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_PROCESSING: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          probablity: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          probablity: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_FAILED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          probablity: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          probablitySelected: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_FAILED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          probablitySelected: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          valueList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_PROCESSING: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          valueList: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          valueList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_FAILED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          valueList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          vulnerabilityList: null,
          error: null,
          loading: false,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_PROCESSING: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          vulnerabilityList: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          vulnerabilityList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_FAILED: {
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          vulnerabilityList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    // ANCHOR MAKE CHANGES ONLY TO REQUESTED ACTION FOR DIFFERENT PAGES HERE
    case ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_LIST_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_LIST_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          validationList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          validationList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          validationList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          listData: [],
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED: {
      return {
        ...state,
        organizationsInformation: {
          ...state.organizationsInformation,
          isProcessing: null,
          isSuccess: null,
          loading: true,
          requested: true,
          hasErrors: null,
          listData: {},
          timezonList: {},
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_TIMEZONE_PROCESSING: {
      return {
        ...state,
        organizationsInformation: {
          ...state.organizationsInformation,
          isProcessing: true,
          loading: true,
          requested: false,
          timezonList: {},
        },
      };
    }
    case ADMINISTRATION_ORAGANIZATION_TIMEZONE_SUCCESSED: {
      return {
        ...state,
        organizationsInformation: {
          ...state.organizationsInformation,
          isProcessing: null,
          isSuccess: null,
          loading: false,
          requested: false,
          hasErrors: null,
          timezonList: action.data,
        },
      };
    }
    case ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED: {
      return {
        ...state,
        organizationsInformation: {
          ...state.organizationsInformation,
          isSuccess: null,
          loading: true,
          requested: true,
          hasErrors: null,
        },
      };
    }
    case ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_SUCCESSED: {
      return {
        ...state,
        organizationsInformation: {
          ...state.organizationsInformation,
          isProcessing: null,
          isSuccess: null,
          loading: false,
          requested: false,
          hasErrors: null,
          listData: action.data,
        },
      };
    }
    case ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_FAILED: {
      return {
        ...state,
        organizationsInformation: {
          ...state.organizationsInformation,
          isProcessing: null,
          isSuccess: false,
          loading: false,
          requested: false,
          hasErrors: action.data,
          listData: {},
        },
      };
    }
    case ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED: {
      return {
        ...state,
        emailConfigurations: {
          ...state.emailConfigurations,
          isSuccess: null,
          loading: true,
          requested: true,
          hasErrors: null,
        },
      };
    }
    case ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_SUCCESSED: {
      return {
        ...state,
        emailConfigurations: {
          ...state.emailConfigurations,
          isProcessing: null,
          isSuccess: null,
          loading: false,
          requested: false,
          hasErrors: null,
          listData: action.data,
        },
      };
    }
    case ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_FAILED: {
      return {
        ...state,
        emailConfigurations: {
          ...state.emailConfigurations,
          isProcessing: null,
          isSuccess: false,
          loading: false,
          requested: false,
          hasErrors: action.data,
          listData: {},
        },
      };
    }

    case ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED: {
      return {
        ...state,
        emailConnectivity: {
          ...state.emailConnectivity,
          isSuccess: null,
          loading: true,
          requested: true,
          hasErrors: null,
        },
      };
    }
    case ADMINISTRATION_GET_EMAIL_CONNECTIVITY_SUCCESSED: {
      return {
        ...state,
        emailConnectivity: {
          ...state.emailConnectivity,
          isProcessing: null,
          isSuccess: null,
          loading: false,
          requested: false,
          hasErrors: null,
          listData: action.data,
        },
      };
    }
    case ADMINISTRATION_GET_EMAIL_CONNECTIVITY_FAILED: {
      return {
        ...state,
        emailConnectivity: {
          ...state.emailConnectivity,
          isProcessing: null,
          isSuccess: false,
          loading: false,
          requested: false,
          hasErrors: action.data,
          listData: {},
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED: {
      return {
        ...state,
        assetDepartmentList: {
          ...state.assetDepartmentList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_PROCESSING: {
      return {
        ...state,
        assetDepartmentList: {
          ...state.assetDepartmentList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED: {
      return {
        ...state,
        assetDepartmentList: {
          ...state.assetDepartmentList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED: {
      return {
        ...state,
        assetDepartmentList: {
          ...state.assetDepartmentList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        casesCategoriesList: {
          ...state.casesCategoriesList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_CATEGORY_LIST_PROCESSING: {
      return {
        ...state,
        casesCategoriesList: {
          ...state.casesCategoriesList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CATEGORY_LIST_SUCCESSED: {
      return {
        ...state,
        casesCategoriesList: {
          ...state.casesCategoriesList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CATEGORY_LIST_FAILED: {
      return {
        ...state,
        casesCategoriesList: {
          ...state.casesCategoriesList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED: {
      return {
        ...state,
        categorydispositionList: {
          ...state.categorydispositionList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_CATEGORY_DISPOSITION_SUCCESSED: {
      return {
        ...state,
        categorydispositionList: {
          ...state.categorydispositionList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CATEGORY_DISPOSITION_FAILED: {
      return {
        ...state,
        categorydispositionList: {
          ...state.categorydispositionList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        casesSubCategoriesList: {
          ...state.casesSubCategoriesList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_SUBCATEGORY_LIST_PROCESSING: {
      return {
        ...state,
        casesSubCategoriesList: {
          ...state.casesSubCategoriesList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_SUBCATEGORY_LIST_SUCCESSED: {
      return {
        ...state,
        casesSubCategoriesList: {
          ...state.casesSubCategoriesList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_SUBCATEGORY_LIST_FAILED: {
      return {
        ...state,
        casesSubCategoriesList: {
          ...state.casesSubCategoriesList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED: {
      return {
        ...state,
        cases: {
          ...state.cases,
          containerList: {},
          requested: true,
          hasError: false,
          isSuccess: false
        }
      };
    }


    case ADMINISTRATION_CASES_CONTAINER_LIST_SUCCESS: {
      return {
        ...state,
        cases: {
          ...state.cases,
          containerList: action.data,
          requested: false,
          hasError: false,
          isSuccess: true
        }
      };
    }


    case ADMINISTRATION_CASES_CONTAINER_LIST_FAILDED: {
      return {
        ...state,
        cases: {
          ...state.cases,
          containerList: {},
          requested: false,
          hasError: true,
          isSuccess: false
        }
      };
    }

    case ADMINISTRATION_CASES_DESPOSITION_SUCCESS: {
      return {
        ...state,
        cases: {
          ...state.cases,
          dispositionList: action.data,
          requested: false,
          hasError: false,
          isSuccess: true
        }
      };
    }

    case ADMINISTRATION_CASES_DESPOSITION_FAILED: {
      return {
        ...state,
        cases: {
          ...state.cases,
          dispositionList: {},
          requested: false,
          hasError: true,
          isSuccess: false
        }
      };
    }

    case ADMINISTRATION_CASES_CATEGORY_SUCCESS: {
      return {
        ...state,
        cases: {
          ...state.cases,
          categoryList: action.data,
          requested: false,
          hasError: false,
          isSuccess: true
        }
      };
    }

    case ADMINISTRATION_CASES_CATEGORY_FAILED: {
      return {
        ...state,
        cases: {
          ...state.cases,
          despositionList: {},
          requested: false,
          hasError: true,
          isSuccess: false
        }
      };
    }


    case ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED: {
      return {
        ...state,
        businessGroupList: {
          ...state.businessGroupList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_PROCESSING: {
      return {
        ...state,
        businessGroupList: {
          ...state.businessGroupList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_SUCCESSED: {
      return {
        ...state,
        businessGroupList: {
          ...state.businessGroupList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_FAILED: {
      return {
        ...state,
        businessGroupList: {
          ...state.businessGroupList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED: {
      return {
        ...state,
        assetTypeList: {
          ...state.assetTypeList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ASSET_TYPE_LIST_PROCESSING: {
      return {
        ...state,
        assetTypeList: {
          ...state.assetTypeList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED: {
      return {
        ...state,
        assetTypeList: {
          ...state.assetTypeList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ASSET_TYPE_LIST_FAILED: {
      return {
        ...state,
        assetTypeList: {
          ...state.assetTypeList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        primarySubCategoryList: {
          ...state.primarySubCategoryList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_PRIMARY_CATEGORY_LIST_SUCCESSED: {
      return {
        ...state,
        primarySubCategoryList: {
          ...state.primarySubCategoryList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_PRIMARY_CATEGORY_LIST_FAILED: {
      return {
        ...state,
        primarySubCategoryList: {
          ...state.primarySubCategoryList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED: {
      return {
        ...state,
        dispositionsList: {
          ...state.dispositionsList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_DISPOSITIONS_LIST_PROCESSING: {
      return {
        ...state,
        dispositionsList: {
          ...state.dispositionsList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_DISPOSITIONS_LIST_SUCCESSED: {
      return {
        ...state,
        dispositionsList: {
          ...state.dispositionsList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_DISPOSITIONS_LIST_FAILED: {
      return {
        ...state,
        dispositionsList: {
          ...state.dispositionsList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED: {
      return {
        ...state,
        subDispositionsList: {
          ...state.subDispositionsList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_PROCESSING: {
      return {
        ...state,
        subDispositionsList: {
          ...state.subDispositionsList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_SUCCESSED: {
      return {
        ...state,
        subDispositionsList: {
          ...state.subDispositionsList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_FAILED: {
      return {
        ...state,
        subDispositionsList: {
          ...state.subDispositionsList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_SUCCESSED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_FAILED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          severityList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_SEVERITY_SUCCESSED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          severityList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          severityList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          tecnicalServerityLevel: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          tecnicalServerityLevel: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_FAILED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          tecnicalServerityLevel: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          nontecnicalServerityLevel: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          nontecnicalServerityLevel: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_FAILED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          nontecnicalServerityLevel: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED: {
      return {
        ...state,
        assetDropDownList: {
          ...state.assetDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          threatList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_PROCESSING: {
      return {
        ...state,
        assetDropDownList: {
          ...state.assetDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_SUCCESSED: {
      return {
        ...state,
        assetDropDownList: {
          ...state.assetDropDownList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_FAILED: {
      return {
        ...state,
        assetDropDownList: {
          ...state.assetDropDownList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED: {
      return {
        ...state,
        assetSubGroupDropDownList: {
          ...state.assetSubGroupDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_PROCESSING: {
      return {
        ...state,
        assetSubGroupDropDownList: {
          ...state.assetSubGroupDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_SUCCESSED: {
      return {
        ...state,
        assetSubGroupDropDownList: {
          ...state.assetSubGroupDropDownList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_FAILED: {
      return {
        ...state,
        assetSubGroupDropDownList: {
          ...state.assetSubGroupDropDownList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED: {
      return {
        ...state,
        assetOwnerDropDownList: {
          ...state.assetOwnerDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_PROCESSING: {
      return {
        ...state,
        assetOwnerDropDownList: {
          ...state.assetOwnerDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_SUCCESSED: {
      return {
        ...state,
        assetOwnerDropDownList: {
          ...state.assetOwnerDropDownList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_FAILED: {
      return {
        ...state,
        assetOwnerDropDownList: {
          ...state.assetOwnerDropDownList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED: {
      return {
        ...state,
        assetDepartmentDropDownList: {
          ...state.assetDepartmentDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_PROCESSING: {
      return {
        ...state,
        assetDepartmentDropDownList: {
          ...state.assetDepartmentDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_SUCCESSED: {
      return {
        ...state,
        assetDepartmentDropDownList: {
          ...state.assetDepartmentDropDownList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_FAILED: {
      return {
        ...state,
        assetDepartmentDropDownList: {
          ...state.assetDepartmentDropDownList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED: {
      return {
        ...state,
        assetValueDropDownList: {
          ...state.assetValueDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_PROCESSING: {
      return {
        ...state,
        assetValueDropDownList: {
          ...state.assetValueDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_SUCCESSED: {
      return {
        ...state,
        assetValueDropDownList: {
          ...state.assetValueDropDownList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_FAILED: {
      return {
        ...state,
        assetValueDropDownList: {
          ...state.assetValueDropDownList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED: {
      return {
        ...state,
        ditectionMethodList: {
          ...state.ditectionMethodList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_DITECTION_METHOD_LIST_PROCESSING: {
      return {
        ...state,
        ditectionMethodList: {
          ...state.ditectionMethodList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_DITECTION_METHOD_LIST_SUCCESSED: {
      return {
        ...state,
        ditectionMethodList: {
          ...state.ditectionMethodList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_SLA_LIST_REQUESTED: {
      return {
        ...state,
        slaList: {
          ...state.slaList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_SLA_LIST_SUCCESSED: {
      return {
        ...state,
        slaList: {
          ...state.slaList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_SLA_LIST_FAILED: {
      return {
        ...state,
        ditectionMethodList: {
          ...state.ditectionMethodList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED: {
      return {
        ...state,
        subDispositionsDropdownList: {
          ...state.subDispositionsDropdownList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_SUCCESSED: {
      return {
        ...state,
        subDispositionsDropdownList: {
          ...state.subDispositionsDropdownList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_FAILED: {
      return {
        ...state,
        subDispositionsDropdownList: {
          ...state.subDispositionsDropdownList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_LIST_REQUESTED: {
      return {
        ...state,
        ncissList: {
          ...state.ncissList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_LIST_PROCESSING: {
      return {
        ...state,
        ncissList: {
          ...state.ncissList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_LIST_SUCCESSED: {
      return {
        ...state,
        ncissList: {
          ...state.ncissList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_LIST_FAILED: {
      return {
        ...state,
        ncissList: {
          ...state.ncissList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED: {
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_LOCATIONS_LIST_PROCESSING: {
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_LOCATIONS_LIST_SUCCESSED: {
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_THREAT_SUCCESSED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          threatList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_LOCATIONS_LIST_FAILED: {
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_VULNERABILITY_THREAT_FAILED: {
      return {
        ...state,
        vulnerabilityList: {
          ...state.vulnerabilityList,
          threatList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_THREAT_INTEL_LIST_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_LIST_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    /*  */
    case ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          severityList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_THREAT_INTEL_SEVERITY_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          severityList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_SEVERITY_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          severityList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        casesNcissDropDownList: {
          ...state.casesNcissDropDownList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_SUCCESSED: {
      return {
        ...state,
        casesNcissDropDownList: {
          ...state.casesNcissDropDownList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_FAILED: {
      return {
        ...state,
        casesNcissDropDownList: {
          ...state.casesNcissDropDownList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED: {
      return {
        ...state,
        ncisscategoriesList: {
          ...state.ncisscategoriesList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_SUCCESSED: {
      return {
        ...state,
        ncisscategoriesList: {
          ...state.ncisscategoriesList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_FAILED: {
      return {
        ...state,
        ncisscategoriesList: {
          ...state.ncisscategoriesList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED: {
      return {
        ...state,
        perioritiesList: {
          ...state.perioritiesList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_SUCCESSED: {
      return {
        ...state,
        perioritiesList: {
          ...state.perioritiesList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_FAILED: {
      return {
        ...state,
        perioritiesList: {
          ...state.perioritiesList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    /*  */
    case ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          categoryList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          categoryList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          categoryList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }




    case ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_GET_DISPOSITION_LIST_PROCESSING: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_DISPOSITION_LIST_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_GET_DISPOSITION_LIST_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case INGESTION_TYPE_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          typeList: null,
        },
      };
    }
    case INGESTION_TYPE_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          typeList: action.data,
        },
      };
    }
    case INGESTION_TYPE_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          typeList: null,
        },
      };
    }

    case INGESTION_WIDGET_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          widgetList: null,
        },
      };
    }
    case INGESTION_WIDGET_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          widgetList: action.data,
        },
      };
    }
    case INGESTION_WIDGET_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          widgetList: null,
        },
      };
    }

    case INGESTION_FREQUENCY_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          frequencyList: null,
        },
      };
    }
    case INGESTION_FREQUENCY_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          frequencyList: action.data,
        },
      };
    }
    case INGESTION_FREQUENCY_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          frequencyList: null,
        },
      };
    }

    case INGESTION_APPLICATION_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          applicationList: null,
        },
      };
    }
    case INGESTION_APPLICATION_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          applicationList: action.data,
        },
      };
    }
    case INGESTION_APPLICATION_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          applicationList: null,
        },
      };
    }

    case INGESTION_USERS_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          usersList: null,
        },
      };
    }
    case INGESTION_USERS_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          usersList: action.data,
        },
      };
    }
    case INGESTION_USERS_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          usersList: null,
        },
      };
    }

    case ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionOutputList: null,
          isDataLoading: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionOutputList: action.data,
          isDataLoading: false,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionOutputList: action.data,
          isDataLoading: false,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_REQUESTED: {
      return {
        ...state,
        integrationScript: {
          ...state.integrationScript,
          isProcessing: true,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_SUCCESSED: {
      return {
        ...state,
        integrationScript: {
          ...state.integrationScript,
          script: action?.data,
          isProcessing: false,
        },
      };
    }
    case ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_FAILED: {
      return {
        ...state,
        integrationScript: {
          ...state.integrationScript,
          script: "",
          isProcessing: false,
        },
      };
    }

    case AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          viewSciptData: null,
          isFieldLoading: true,
        },
      };
    }
    case AUTOMATION_ACTION_VIEW_SCRIPT_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          viewSciptData: action.data,
          isFieldLoading: false,
        },
      };
    }
    case AUTOMATION_ACTION_VIEW_SCRIPT_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          viewSciptData: action.data,
          isFieldLoading: false,
        },
      };
    }
    case AUTOMATION_ACTION_CONFIGURATION_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          appConfigurationData: null,
          isConfigLoading: true,
        },
      };
    }
    case AUTOMATION_ACTION_CONFIGURATION_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          appConfigurationData: action.data,
          isConfigLoading: false,
        },
      };
    }
    case AUTOMATION_ACTION_CONFIGURATION_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          appConfigurationData: action.data,
          isConfigLoading: false,
        },
      };
    }

    case INGESTION_ACTION_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionList: null,
        },
      };
    }
    case INGESTION_ACTION_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionList: action.data,
        },
      };
    }
    case INGESTION_ACTION_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionList: null,
        },
      };
    }

    case ACTIONS_TYPE_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsTypeList: null,
        },
      };
    }
    case ACTIONS_TYPE_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsTypeList: action.data,
        },
      };
    }
    case ACTIONS_TYPE_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsTypeList: null,
        },
      };
    }

    case ACTIONS_SCRIPT_TYPE_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsScriptList: null,
        },
      };
    }
    case ACTIONS_SCRIPT_TYPE_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsScriptList: action.data,
        },
      };
    }
    case ACTIONS_SCRIPT_TYPE_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsScriptList: null,
        },
      };
    }

    case ACTIONS_IO_TYPE_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsIOTypeList: null,
        },
      };
    }
    case ACTIONS_IO_TYPE_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsIOTypeList: action.data,
        },
      };
    }
    case ACTIONS_IO_TYPE_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsIOTypeList: null,
        },
      };
    }

    case ACTIONS_APPLICATION_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsApplicationList: [],
        },
      };
    }
    case ACTIONS_APPLICATION_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsApplicationList: action.data,
        },
      };
    }
    case ACTIONS_APPLICATION_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          actionsApplicationList: null,
        },
      };
    }

    case GET_INGESTION_MULTI_DATA_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          mappingData: null,
          isFieldLoading: true
        },
      };
    }
    case GET_INGESTION_MULTI_DATA_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          mappingData: action.data,
          isFieldLoading: false
        },
      };
    }
    case GET_INGESTION_MULTI_DATA_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          mappingData: null,
          isFieldLoading: false
        },
      };
    }
    case GET_INGESTION_MAPPING_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          mappingDropDownData: null,
          isFieldLoading: true
        },
      };
    }
    case GET_INGESTION_MAPPING_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          mappingDropDownData: action.data,
          isFieldLoading: false
        },
      };
    }
    case GET_INGESTION_MAPPING_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          mappingDropDownData: null,
          isFieldLoading: false
        },
      };
    }

    case ADMINISTRATION_LOGS_APPS_LIST_REQUESTED: {
      return {
        ...state,
        logsList: {
          ...state.logsList,
          isProcessing: true,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED: {
      return {
        ...state,
        logsList: {
          ...state.logsList,
          isProcessing: true,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED: {
      return {
        ...state,
        logsList: {
          ...state.logsList,
          isProcessing: true,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED: {
      return {
        ...state,
        logsList: {
          ...state.logsList,
          isProcessing: true,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED: {
      return {
        ...state,
        logsList: {
          ...state.logsList,
          isProcessing: true,
          listData: null,
        }
      }
    }
    case ADMINISTRATION_LOGS_LIST_SUCCESSED: {
      return {
        ...state,
        logsList: {
          ...state.logsList,
          isProcessing: false,
          listData: action.data,
        },
      };
    }
    case APP_LOGS_NOTIFICATION_LIST_SUCCESSED: {
      return {
        ...state,
        appLogsList: {
          ...state.logsList,
          isProcessing: false,
          listData: action.data,
        },
      };
    }
    case SET_ALERT_COUNT: {
      return {
        ...state,
        alertCount: action.payload
      };
    }

    case ADMINISTRATION_LOGS_LIST_FAILED: {
      return {
        ...state,
        logsList: {
          ...state.logsList,
          isProcessing: false,
          listData: null,
        },
      };
    }
    case APP_LOGS_NOTIFICATION_LIST_REQUESTED: {
      return {
        ...state,
        appLogsList: {
          ...state.appLogsList,
          isProcessing: true,
          listData: null,
        },
      };
    }
    case APP_LOGS_NOTIFICATION_LIST_SUCCESSED: {
      return {
        ...state,
        appLogsList: {
          ...state.appLogsList,
          isProcessing: false,
          listData: action.data,
        },
      };
    }
    case APP_LOGS_NOTIFICATION_LIST_FAILED: {
      return {
        ...state,
        appLogsList: {
          ...state.appLogsList,
          isProcessing: false,
          listData: null,
        },
      };
    }

    case ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED: {
      return {
        ...state,
        productsList: {
          ...state.productsList,
          isProcessing: true,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_PRODUCTS_LIST_SUCCESSED: {
      return {
        ...state,
        productsList: {
          ...state.productsList,
          isProcessing: false,
          listData: action.data,
        },
      };
    }
    case ADMINISTRATION_PRODUCTS_LIST_FAILED: {
      return {
        ...state,
        productsList: {
          ...state.productsList,
          isProcessing: false,
          listData: null,
        },
      };
    }

    case ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          isProcessing: true,
          listData: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_LIST_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          isProcessing: false,
          listData: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_LIST_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          isProcessing: false,
          listData: null,
        },
      };
    }

    case ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authenticateList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authenticateList: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authenticateList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_PERMISSION_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          permissionList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_PERMISSION_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          permissionList: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_PERMISSION_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          permissionList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          dashboardList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_DASHBOARDS_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          dashboardList: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_DASHBOARDS_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          dashboardList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          landingList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_LANDING_PAGE_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          landingList: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_LANDING_PAGE_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          landingList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_GROUP_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          groupList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_GROUP_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          groupList: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_GROUP_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          groupList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          sessionPassword: null,
          isFieldLoading: true
        },
      };
    }
    case ADMINISTRATION_ACCESS_SESSION__PASSWORD_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          sessionPassword: action.data,
          isFieldLoading: false
        },
      };
    }
    case ADMINISTRATION_ACCESS_SESSION__PASSWORD_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          sessionPassword: action.data,
          isFieldLoading: false
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          error: null,
          accessGroups: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTORL_GROUPS_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          accessGroups: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTORL_GROUPS_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          listData: null,
          accessGroups: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          roles: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTORL_ROLES_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          roles: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTORL_ROLES_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          roles: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          error: null,
          actions: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          actions: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          actions: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          actionGroup: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          actionGroup: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          actionGroup: action.data,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          error: null,
          actionGroupTab: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          actionGroupTab: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          actionGroupTab: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          error: null,
          authSetting: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authSetting: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authSetting: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authenticationTypeList: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authenticationTypeList: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authenticationTypeList: null,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authencticationVendor: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authencticationVendor: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          authencticationVendor: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          error: null,
          previleges: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          previleges: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          previleges: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          error: null,
          previlegesGroup: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          previlegesGroup: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          previlegesGroup: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }



    case ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          error: null,
          allPrevileges: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_SUCCESSED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          allPrevileges: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_FAILED: {
      return {
        ...state,
        accessControlList: {
          ...state.accessControlList,
          allPrevileges: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          feedback: null,
          hasErrors: action.data,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          feedback: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          feedback: null,
          hasErrors: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetList: null,
          hasErrors: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetList: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetList: null,
          hasErrors: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetSources: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetSources: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetSources: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetGroups: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetGroups: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          widgetGroups: null,
        },
      };
    }
    case ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          licenseSources: null,
        },
      };
    }
    case ADMINISTRATION_PRODUCT_LICENSE_SOURCES_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          licenseSources: action.data,
        },
      };
    }
    case ADMINISTRATION_PRODUCT_LICENSE_SOURCES_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          licenseSources: null,
        },
      };
    }

    case ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          licenseInfo: null,
          hasErrors: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED: {
      return {
        ...state,
        riskMatrixList: {
          ...state.riskMatrixList,
          isProcessing: true,
          isSuccess: false,
          hasErrors: null,
          // listData: action.data,
        }
      }
    }
    case ADMINISTRATION_RISK_MATRIX_LIST_SUCCESSED: {
      return {
        ...state,
        riskMatrixList: {
          ...state.riskMatrixList,
          isProcessing: false,
          isSuccess: true,
          hasErrors: null,
          listData: action.data,
        }
      }
    }

    case ADMINISTRATION_RISK_MATRIX_LIST_FAILED: {
      // debugger;
      return {
        ...state,
        riskMatrixList: {
          ...state.riskMatrixList,
          isProcessing: false,
          isSuccess: false,
          hasErrors: action.data,
          // listData: action.data,
        }
      }
    }

    case ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED: {
      return {
        ...state,
        riskMetaList: {
          ...state.riskMetaList,
          requested: true,
          isSuccess: false,
          hasErrors: false,
          listData: null
        }
      }
    }


    case ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_SUCCESSED: {
      return {
        ...state,
        riskMetaList: {
          ...state.riskMetaList,
          requested: false,
          isSuccess: true,
          hasErrors: false,
          listData: action.data
        }
      }
    }

    case ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_FAILED: {
      return {
        ...state,
        riskMetaList: {
          ...state.riskMetaList,
          requested: false,
          isSuccess: false,
          hasErrors: true,
          listData: null
        }
      }
    }


    case ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          licenseInfo: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          licenseInfo: null,
          hasErrors: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          backupList: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          backupList: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          backupList: null,
          hasErrors: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_SUCCESSED: {
      return {
        ...state,
        selectedBackupSetting: {
          listData: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_SUCCESSED: {
      return {
        ...state,
        selectedSftpConfigure: {
          listData: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_FAILED: {
      return {
        ...state,
        selectedBackupSetting: {
          listData: null,
          hasErrors: action.data,
          loading: false,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_FAILED: {
      return {
        ...state,
        selectedSftpConfigure: {
          listData: null,
          hasErrors: action.data,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          healthData: null,
          hasErrors: false,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          healthData: action.data,
          hasErrors: false,
          loading: false,
          isSuccess: true,
          requested: false,
        },
      };
    }


    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          healthData: null,
          hasErrors: true,
          loading: false,
          isSuccess: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          healthData: {
            ...state.productSettingList.healthData,
            Connectivity: action.data?.Connectivity,
          },
          hasErrors: false,
          loading: false,
          isSuccess: true,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_NOTIFICATIONS_LIST_SUCCESSED: {
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          listData: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_NOTIFICATIONS_LIST_FAILED: {
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          notifyUser: null,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_SUCCESSED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          notifyUser: action.data,
        },
      };
    }
    case ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_FAILED: {
      return {
        ...state,
        productSettingList: {
          ...state.productSettingList,
          notifyUser: null,
        },
      };
    }

    case ADMINISTRATION_FIELD_SETTING_LIST_SUCCESSED: {
      return {
        ...state,
        fieldSettingsList: {
          ...state.fieldSettingsList,
          listData: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_FIELD_SETTING_LIST_FAILED: {
      return {
        ...state,
        fieldSettingsList: {
          ...state.fieldSettingsList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASE_FIELD_SETTING_LIST_SUCCESSED: {
      return {
        ...state,
        caseFieldSettingsList: {
          ...state.caseFieldSettingsList,
          listData: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED: {
      return {
        ...state,
        caseFieldSettingsList: {
          ...state.caseFieldSettingsList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_TABS_HEADING_SUCCESSED: {
      return {
        ...state,
        allTabsHeading: {
          ...state.allTabsHeading,
          listData: {
            ...state.allTabsHeading.listData,
            ...action.data
          },
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_GET_TABS_HEADING_FAILED: {
      return {
        ...state,
        allTabsHeading: initialState.allTabsHeading,
      };
    }

    case ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_SUCCESSED: {
      return {
        ...state,
        riskMatrixRngeFrom: {
          listData: action.data,
          hasErrors: false,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_FAILED: {
      return {
        ...state,
        riskMatrixRngeFrom: {
          listData: null,
          hasErrors: true,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_SUCCESSED: {
      return {
        ...state,
        riskMatrixRngeTo: {
          listData: action.data,
          hasErrors: false,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_FAILED: {
      return {
        ...state,
        riskMatrixRngeTo: {
          listData: null,
          hasErrors: true,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_SUCCESSED: {
      return {
        ...state,
        riskMatrixColorList: {
          listData: action.data,
          hasErrors: false,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_FAILED: {
      return {
        ...state,
        riskMatrixColorList: {
          listData: null,
          hasErrors: true,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED: {
      return {
        ...state,
        slaLogViewList: {
          listData: null,
          hasErrors: false,
          loading: true,
          requested: true,
        },
      };      
    }

    case ADMINISTRATION_CASES_SLA_VIEW_LIST_SUCCESSED: {
      return {
        ...state,
        slaLogViewList: {
          listData: action.data,
          hasErrors: false,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_SLA_VIEW_LIST_FAILED: {
      return {
        ...state,
        slaLogViewList: {
          listData: null,
          hasErrors: true,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED: {
      return {
        ...state,
        slaTRuleViewList: {
          listData: null,
          hasErrors: false,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_CASES_SLA_RULE_LIST_SUCCESSED: {
      return {
        ...state,
        slaTRuleViewList: {
          listData: action.data,
          hasErrors: false,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_SLA_RULE_LIST_FAILED: {
      return {
        ...state,
        slaTRuleViewList: {
          listData: null,
          hasErrors: true,
          loading: false,
          requested: false,
        },
      };
    }
    //added by fida

    case ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_PROCESSING: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_PROCESSING: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          threatActorList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          threatActorList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          threatActorList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }



    case ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }


    case ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_PROCESSING: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }




    case ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_NOTIFICATIONS_LOG_LIST_SUCCESSED: {
      return {
        ...state,
        logNotificationList: {
          ...state.logNotificationList,
          listData: action.data,
          hasErrors: null,
          loading: false,
          requested: true,
        },
      };
    }

    case ADMINISTRATION_NOTIFICATIONS_LOG_LIST_FAILED: {
      return {
        ...state,
        logNotificationList: {
          ...state.logNotificationList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_LESSON_LIST_REQUESTED: {
      return {
        ...state,
        lessonsList: {
          ...state.lessonsList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_LESSON_LIST_PROCESSING: {
      return {
        ...state,
        lessonsList: {
          ...state.lessonsList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED: {
      return {
        ...state,
        lessonsList: {
          ...state.lessonsList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_LESSON_LIST_FAILED: {
      return {
        ...state,
        lessonsList: {
          ...state.lessonsList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED: {
      return {
        ...state,
        containedByList: {
          ...state.containedByList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_CONTAINED_BY_LIST_PROCESSING: {
      return {
        ...state,
        containedByList: {
          ...state.containedByList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED: {
      return {
        ...state,
        containedByList: {
          ...state.containedByList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED: {
      return {
        ...state,
        containedByList: {
          ...state.containedByList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED: {
      return {
        ...state,
        containmentStatusList: {
          ...state.containmentStatusList,
          listData: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_PROCESSING: {
      return {
        ...state,
        containmentStatusList: {
          ...state.containmentStatusList,
          listData: null,
          error: null,
          loading: true,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED: {
      return {
        ...state,
        containmentStatusList: {
          ...state.containmentStatusList,
          listData: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }

    case ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED: {
      return {
        ...state,
        containmentStatusList: {
          ...state.containmentStatusList,
          listData: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          affectedVendorList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AFFECTED_VENDOR_LIST_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          affectedVendorList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AFFECTED_VENDOR_LIST_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          affectedVendorList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          affectedProductList: null,
          error: null,
          loading: true,
          requested: true,
        },
      };
    }
    case ADMINISTRATION_AFFECTED_PRODUCT_LIST_SUCCESSED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          affectedProductList: action.data,
          error: null,
          loading: false,
          requested: false,
        },
      };
    }
    case ADMINISTRATION_AFFECTED_PRODUCT_LIST_FAILED: {
      return {
        ...state,
        threatIntellList: {
          ...state.threatIntellList,
          affectedProductList: null,
          error: action.data,
          loading: false,
          requested: false,
        },
      };
    }
    case PRE_INGESTION_RULE_REQUESTED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          preIngestionRuleList: null,
        },
      };
    }
    case PRE_INGESTION_RULE_SUCCESSED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          preIngestionRuleList: action.data,
        },
      };
    }
    case PRE_INGESTION_RULE_FAILED: {
      return {
        ...state,
        automation: {
          ...state.automation,
          preIngestionRuleList: null,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default administrationReducer;
