import axios from 'axios';
import API from '../config/endpoints.config';

const FileDownload = require('js-file-download');

export async function caseDetailsSaga(id, param, isEdit) {
  let api;
  const userToken = localStorage.getItem('AccessToken');

  let fields = [];

  if (isEdit) {
    fields = [
      'incidentArtifact',
      'evidenceAttachment',
      'ncisPriority'
    ];
  } else {
    fields = [
      'itiSource',
      'itiCategory',
      'itiDisposition',
      'itiAwareIncident',
      'itiDispositionSubCategory',
      'itiLocation',
      'itiOpenedBy',
      'itiClosedBy',
      'itiAssignTo',
      'itiVulnerabilities',
      'itiVulnerabilities.compromisedAssets',
      'members',
      'incidentAssets',
      'evidenceAttachment',
      'itiSubcategory',
      'relatedCases',
      'vulnerabilities',
      'vulnerabilities.vuaAsset.asset',
      'advisories',
      'risks',
      'mitreSubTechniques', 'mitreTactics', 'mitreTechniques', 'slaInfo',
      'vmAsset'
    ]
  }

  const expandedString = fields.toString();

  switch (param.toLowerCase()) {
    case "cases":
      api = `${API.cases}/${id}?expand=${expandedString}`;
      break;
    case "incident":
      api = `${API.baseUrl}/incident-management/${id}?expand=${expandedString}`;
      break;
    case "vulnerability":
      api = `${API.baseUrl}/cases-vulnerability/${id}?expand=${expandedString}`;
      break;
    case "risk":
      api = `${API.baseUrl}/cases-risk/${id}?expand=${expandedString}`;
      break;
    case "advisory":
      api = `${API.baseUrl}/cases-advisory/${id}?expand=${expandedString},advVendors,advProducts`;
      break;
    default:
      api = `${API.cases}/${id}?expand=${expandedString}`;
  }

  const response = await axios.get(api,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function caseFieldsDetailsSaga(id, fields, evidenceKey, page, searchValue) {
  const userToken = localStorage.getItem('AccessToken');
  const expanedString = fields.toString();
  let api = `${API.baseUrl}/cases-advisory/${id}?art_id=${evidenceKey || null}&index=${page == null ? null : 0}&expand=${expanedString}`;

  if (searchValue) {
    api = api + `&search=${searchValue}`
  }
  const response = await axios.get(api,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function caseVulnerabilityFieldsDetailsSaga(id, fields) {
  const userToken = localStorage.getItem('AccessToken');
  const expandedString = fields.toString();
  const api = `${API.baseUrl}/cases-vulnerability/${id}?expand=${expandedString}`;

  const response = await axios.get(api,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}



export async function createSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.caseManagementModule.update, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function editCaseSaga(data) {
  if (data?.data?.atOnce) {
    let api;
    const { id } = data?.data
    switch (data?.caseType.toLowerCase()) {
      case "cases":
        api = `${API.cases}/${id}`;
        break;
      case "incident":
        api = `${API.baseUrl}/incident-management/${id}`;
        break;
      case "vulnerability":
        api = `${API.baseUrl}/cases-vulnerability/${id}`;
        break;
      case "risk":
        api = `${API.baseUrl}/cases-risk/${id}`;
        break;
      case "advisory":
        api = `${API.baseUrl}/cases-advisory/${id}`;
        break;
      default:
        api = `${API.cases}/${id}`;
    }

    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.put(api, data?.data?.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response.data;
  } else {
    const url = data?.url;
    delete data?.id;
    delete data?.url;
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.put(url, data, {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response.data;
  }
}

export async function exportPdf(url = API.cases + '/export-pdf') {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
    responseType: 'blob',
  });
  FileDownload(response.data, 'export.pdf');

  return response.data;
}

export async function exportExcel(url) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
    responseType: 'blob',
  });
  FileDownload(response.data, 'export.xlsx');

  return response.data;
}

export async function sendEmailSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api;

  switch (payload?.caseType.toLowerCase()) {
    case "cases":
      api = `${API.cases}/email?id=${payload.id}`;
      break;
    case "incident":
      api = `${API.baseUrl}/incident-management/email?id=${payload.id}`;
      break;
    case "vulnerability":
      api = `${API.baseUrl}/cases-vulnerability/email?id=${payload.id}`;
      break;
    case "risk":
      api = `${API.baseUrl}/cases-risk/email?id=${payload.id}`;
      break;
    case "advisory":
      api = `${API.baseUrl}/cases-advisory/email?id=${payload.id}`;
      break;
    default:
      api = `${API.cases}/email?id=${payload.id}`;
  }

  const response = await axios.post(
    api,
    payload.data,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function postComment({ id, payload }) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.caseManagementModule.comment.post + '?id=' + id,
    payload?.data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    const postedComment = response?.data?.data?.data?.[0];
    if (postedComment) {
      postedComment.attachment = response?.data?.data?.data?.[2];
    }

    payload.callback(postedComment);
  }
  return response.data;
}

export async function deleteCaseAsset(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.incidentManagementModule.asset.deleteCall + '?id=' + payload?.id,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    const postedComment = response?.data?.data?.data?.[0];
    if (postedComment) {
      postedComment.attachment = response?.data?.data?.[2];
    }

    payload.callback(postedComment);
  }
  return response.data;
}

export async function addMemberToCase(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.caseManagementModule.member.add + '?id=' + payload?.id,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    payload.callback();
  }
  return response.data;
}
