import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row } from 'antd';
import SPButton from '../../../../../components/SPButton';
import InputBox from '../../../../../components/InputBox';
import SpLoader from '../../../../../components/SPLoader';
import { getUserProfile, getGoogleQrCode, googleAuthProfile, } from '../../../../../actions/user';
import {
  FormContainer,
  MyForm,
  PrimaryText,
  StyledGooglAuthBlock
} from './StyledComponents';
import {
  LabelTitleGoogleAuth,
  InstructionsDiv,
  InstructionLine,
  CenterImageDiv,
  BoxinputDiv
} from '../../../../layout/header/components/ProfileForm/StyledComponents';

const Form = ({ googleAuthProfile, getUserProfile, getGoogleQrCode, isGoogleAuthUpdate, hasGoogleAuthError, qr_image_url, userProfile }) => {

  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('AccessToken', localStorage.getItem('AccessToken2FA'));
    getGoogleQrCode();
    getUserProfile();
    localStorage.removeItem('AccessToken');
  }, []);

  useEffect(() => {
    if (isGoogleAuthUpdate) {
      setTimeout(function () { history.push('/') }, 3000);
    }
  }, [isGoogleAuthUpdate]);

  useEffect(() => {
    formik.setFieldValue('mastercode', (userProfile?.profile && userProfile?.profile?.usr_google_auth_master_code) || '');
  }, [userProfile]);

  const formik = useFormik({
    initialValues: {
      code: '',
      mastercode: (userProfile?.profile && userProfile?.profile?.usr_google_auth_master_code) || '',
    },

    validationSchema: Yup.object({
      code: Yup.string().required('Required'),
      mastercode: Yup.string().required('Required'),
    }),

    onSubmit: values => {
      const { code, mastercode } = values;
      googleAuthProfile({ code, mastercode });
    }
  })

  return (
    <FormContainer>
      <br />
      <MyForm onSubmit={formik.handleSubmit}>
        <h1 style={{ color: '#fff', textAlign: 'center' }}> Configure Two Factor Authentication </h1>
        <Row>
          <PrimaryText>
            Step 1. You must install the Google Authenticator app (Android /
            iOS) on your mobile device.
          </PrimaryText>

          <PrimaryText>
            Step 2.   Follow the below instructions
          </PrimaryText>
        </Row>
        <Row>
          <StyledGooglAuthBlock>
            <LabelTitleGoogleAuth>Instructions</LabelTitleGoogleAuth>
            <InstructionsDiv>
              <InstructionLine>
                1. Open the Authenticator app on your device
              </InstructionLine>
              <InstructionLine>
                2. Tap on &quot;BEGIN SETUP&quot;
              </InstructionLine>
              <InstructionLine>
                3. Tap &quot;Scan a barcode&quot; (if you have not
                installed a barcode scanner, you will now be asked to)
              </InstructionLine>
              <InstructionLine>
                4. Scan the QR code below
              </InstructionLine>
              <InstructionLine>
                5. Click the &quot;Next&quot; button
              </InstructionLine>
              <InstructionLine>
                6. Type the code you see on your mobile device into the
                &quot;Code box&quot; below.
              </InstructionLine>
              <InstructionLine>
                7. Type the Master code. A password or passphrase used
                in case you don&apos;t have access to your device.
              </InstructionLine>
              <InstructionLine>
                8. Click the &quot;Save&quot; button to complete your
                setup
              </InstructionLine>
            </InstructionsDiv>
            <BoxinputDiv>
              <InputBox
                id="code"
                label="Code"
                name="code"
                placeholder="Code"
                type="password"
                onInputChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.code}
                value={formik?.values.code}
                touched={formik?.touched.code}
              />
            </BoxinputDiv>
            <BoxinputDiv>
              <InputBox
                id="mastercode"
                label="Master Code"
                name="mastercode"
                placeholder="Master Code"
                type="password"
                onInputChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.mastercode}
                value={formik?.values.mastercode}
                touched={formik?.touched.mastercode}
              />
            </BoxinputDiv>

            <CenterImageDiv>
              {qr_image_url ? <img src={qr_image_url} alt="QR" /> : <SpLoader isLoading={true} />}
            </CenterImageDiv>
            <div style={{ width: '70px', float: 'right' }}>
              <SPButton
                title="Save"
                htmlType="submit"
                size="small"
              />
            </div>
          </StyledGooglAuthBlock>
        </Row>
      </MyForm >
    </FormContainer >
  );
};

const mapStateToProps = state => {
  return {
    googleAuthProfileData: state.userStore.googleAuthProfile,
    isGoogleAuthUpdate: state.userStore.isGoogleAuthUpdate,
    hasGoogleAuthError: state.userStore.hasGoogleAuthError,
    qr_image_url: state.userStore.qrData?.data?.qr_image_url,
    userProfile: state.userStore?.userProfile?.data,
  };
};

const mapDispatchToProps = {
  googleAuthProfile,
  getUserProfile,
  getGoogleQrCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
