import { put, call, takeLatest } from 'redux-saga/effects';
import {
  HIDE_LOADER,
  SHOW_LOADER,
  AUTOMATION_LIST_FAILED,
  AUTOMATION_LIST_REQUESTED,
  AUTOMATION_LIST_SUCCESSED,
  AUTOMATION_DETAILS_LIST_REQUESTED,
  AUTOMATION_DETAILS_LIST_SUCCESSED,
  AUTOMATION_DETAILS_LIST_FAILED,
  DELETE_AUTOMATION_REQUESTED,
  DELETE_AUTOMATION_SUCCESSED,
  DELETE_AUTOMATION_FAILED,
  GET_AUTOMATION_APPLICATION_LIST_REQUESTED,
  GET_AUTOMATION_ACTION_TYPES_REQUESTED,
  GET_AUTOMATION_ACTION_LIST_REQUESTED,
  GET_AUTOMATION_INPUT_LIST_REQUESTED,
  GET_AUTOMATION_APPLICATION_LIST_SUCCESSED,
  GET_AUTOMATION_APPLICATION_LIST_FAILED,
  GET_AUTOMATION_ACTION_LIST_SUCCESSED,
  GET_AUTOMATION_ACTION_LIST_FAILED,
  GET_AUTOMATION_INPUT_LIST_SUCCESSED,
  GET_AUTOMATION_INPUT_LIST_FAILED,
  CREATE_AUTOMATION_REQUESTED,
  CREATE_AUTOMATION_FAILED,
  AUTOMATION_IMPORT_REQUESTED,
  AUTOMATION_IMPORT_SUCCESSED,
  AUTOMATION_IMPORT_FAILED,
  AUTOMATION_ARTIFACT_LIST_REQUESTED,
  AUTOMATION_ARTIFACT_TYPE_LIST_REQUESTED,
  AUTOMATION_ARTIFACT_LIST_SUCCESSED,
  AUTOMATION_ARTIFACT_LIST_FAILED,
  AUTOMATION_ARTIFACT_TYPE_LIST_SUCCESSED,
  AUTOMATION_ARTIFACT_TYPE_LIST_FAILED,
  CREATE_AUTOMATION_ARTIFACT_SUCCESSED,
  AUTOMATION_ARTIFACT_DELETE_REQUESTED,
  AUTOMATION_ARTIFACT_DELETE_SUCCESSED,
  AUTOMATION_ARTIFACT_DELETE_FAILED,
  CREATE_AUTOMATION_ARTIFACT_FAILED,
  CREATE_AUTOMATION_ARTIFACT_REQUESTED,
  AUTOMATION_APPROVAL_LIST_REQUESTED,
  AUTOMATION_APPROVAL_LIST_SUCCESSED,
  AUTOMATION_APPROVAL_LIST_FAILED,
  NOTIFICATION_APPROVAL_LIST_REQUESTED,
  NOTIFICATION_APPROVAL_LIST_SUCCESSED,
  NOTIFICATION_APPROVAL_LIST_FAILED,
  GET_AUTOMATION_EXECUTION_APPLICATION_LIST_SUCCESSED,
  GET_AUTOMATION_EXECUTION_APPLICATION_LIST_FAILED,
  GET_AUTOMATION_EXECUTION_APPLICATION_LIST_REQUESTED,
  GET_AUTOMATION_EXECUTION_ACTION_LIST_REQUESTED,
  GET_AUTOMATION_EXECUTION_ACTION_LIST_SUCCESSED,
  GET_AUTOMATION_EXECUTION_ACTION_LIST_FAILED,
  AUTOMATION_EXECUTION_REQUESTED,
  AUTOMATION_EXECUTION_SUCCESSED,
  AUTOMATION_EXECUTION_FAILED,
  AUTOMATION_UPDATE_REQUESTED,
  AUTOMATION_UPDATE_SUCCESSED,
  AUTOMATION_UPDATE_FAILED,
  AUTOMATION_ARTIFACT_OCCURENCE_LIST_REQUESTED,
  AUTOMATION_ARTIFACT_OCCURENCE_LIST_SUCCESSED,
  AUTOMATION_ARTIFACT_OCCURENCE_LIST_FAILED,
  AUTOMATION_RECORD_APPROVE_REQUESTED,
  BULK_APPROVE_REQUESTED,
  BULK_APPROVE_SUCCESSED,
  BULK_APPROVE_FAILED,
  BULK_DECLINE_REQUESTED,
  BULK_DECLINE_SUCCESSED,
  BULK_DECLINE_FAILED,
  GET_APPROVAL_BY_ID_REQUESTED,
  GET_APPROVAL_BY_ID_SUCCESSED,
  GET_APPROVAL_BY_ID_FAILED,
  CHANGE_STATUS_AUTOMATION_REQUESTED,
  CHANGE_STATUS_AUTOMATION_SUCCESSED,
  CHANGE_STATUS_AUTOMATION_FAILED,
  AUTOMATION_GENERATE_REPORT_SUCCESSED,
  AUTOMATION_GENERATE_REPORT_FAILED,
  AUTOMATION_GENERATE_REPORT_REQUESTED,
  GET_AUTOMATION_ACTION_TYPES_SUCCESSED,
  GET_AUTOMATION_ACTION_TYPES_FAILED,
} from '../../constants/actionTypes';
import {
  listAutomationUser,
  automationDetailsSaga,
  automationDeleteSaga,
  automationApplicationSaga,
  automationActionSaga,
  automationInputSaga,
  automationCreateSaga,
  automationImportSaga,
  listAutomationArtifactUser,
  automationArtifactTypeSaga,
  automationArtifactCreateSaga,
  listAutomationApprovalUser,
  automationAritifactDelete,
  approveRecordSaga,
  automationArtifactExecuteApplicationSaga,
  automationArtifactExecuteActionSaga,
  ArtifactExecuteSaga,
  automationAritifactUpdate,
  occurenceListAPI,
  bulkDecline,
  bulkApprove,
  approveRecordDetailSaga,
  automationStatusSaga,
  generateReport,
  automationActionTypesSaga
} from '../../api/automationSaga';
import {
  automationApprovalsList,
  automationArtifactList,
  listAutomationManagement,
  notificationApprovalsList,
} from '../../actions/automation';
import { showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

function delay(duration) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration)
  })
  return promise
}

export function* watchUsersList(action) {
  try {
    var cnt = false;
    while (window.location.pathname.includes("/automationPlayground") && !window.location.pathname.includes("/automationPlayground/artifacts")) {
      const response = yield call(listAutomationUser, action.payload);
      if (response.success) {
        yield put({ type: AUTOMATION_LIST_SUCCESSED, data: response });
        if (cnt) {
          yield call(delay, 5000);
        }
      } else {
        yield put({ type: AUTOMATION_LIST_FAILED, data: null });
      }
      cnt = true;
    }
  } catch (err) {
    yield put({
      type: AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchUsersDetails(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(automationDetailsSaga, action.payload);
    if (response.success) {
      yield put({
        type: AUTOMATION_DETAILS_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: AUTOMATION_DETAILS_LIST_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: AUTOMATION_DETAILS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchApprovalDetails(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(approveRecordDetailSaga, action.payload);
    if (response.success) {
      yield put({
        type: GET_APPROVAL_BY_ID_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: GET_APPROVAL_BY_ID_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: GET_APPROVAL_BY_ID_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchUserDelete(action) {
  const loaderHandle = showToastLoader('Deleting action...')
  try {
    const response = yield call(automationDeleteSaga, action.payload);
    if (response.success) {
      showToastSuccess('Action deleted', loaderHandle)
      yield put({ type: DELETE_AUTOMATION_SUCCESSED, data: response });
      yield put(listAutomationManagement({ queryItem: action.query }));
    } else {
      showToastError('Action delete failed', loaderHandle)
      yield put({ type: DELETE_AUTOMATION_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Action delete failed', loaderHandle)
    yield put({
      type: DELETE_AUTOMATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchChangeStatus(action) {
  const loaderHandle = showToastLoader('Changing status...')
  try {
    const response = yield call(automationStatusSaga, action.payload);

    if (response.success) {
      showToastSuccess('Status changed', loaderHandle)
      yield put({ type: CHANGE_STATUS_AUTOMATION_SUCCESSED, data: response });
      yield put(listAutomationManagement({ queryItem: action.query }));
    } else {
      showToastError('Status change failed', loaderHandle)
      yield put({ type: CHANGE_STATUS_AUTOMATION_FAILED, data: null });
    }
  } catch (err) {

    showToastError('Status change failed', loaderHandle)
    yield put({
      type: CHANGE_STATUS_AUTOMATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchUpdateArtifact(action) {
  const loaderHandle = showToastLoader('Updating artifact...')
  try {
    const response = yield call(
      automationAritifactUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      showToastSuccess('Artifact updated', loaderHandle)
      yield put({ type: AUTOMATION_UPDATE_SUCCESSED, data: response });
      yield put(automationArtifactList({ queryItem: action.query }));
    } else {
      showToastError('Artifact update failed', loaderHandle)
      yield put({ type: AUTOMATION_UPDATE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Artifact update failed', loaderHandle)
    yield put({
      type: AUTOMATION_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchArtifactDelete(action) {
  const loaderHandle = showToastLoader('Deleting artifact...')
  try {
    const response = yield call(automationAritifactDelete, action.payload);
    if (response.success) {
      showToastSuccess('Artifact deleted', loaderHandle)
      yield put({ type: AUTOMATION_ARTIFACT_DELETE_SUCCESSED, data: response });
      yield put(automationArtifactList({ queryItem: action.query }));
    } else {
      showToastError('Artifact delete failed', loaderHandle)
      yield put({
        type: AUTOMATION_ARTIFACT_DELETE_FAILED,
        data: null,
      });
    }
  } catch {
    showToastError('Artifact delete failed', loaderHandle)
    yield put({
      type: AUTOMATION_ARTIFACT_DELETE_FAILED,
      data: null,
    });
  }
}

export function* watchApplicationList(action) {
  try {
    const response = yield call(automationApplicationSaga, action.payload);
    if (response.success) {
      yield put({
        type: GET_AUTOMATION_APPLICATION_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: GET_AUTOMATION_APPLICATION_LIST_SUCCESSED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_AUTOMATION_APPLICATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* watchActionTypesList(action) {
  try {
    const response = yield call(automationActionTypesSaga, action.payload);
    if (response.success) {
      yield put({
        type: GET_AUTOMATION_ACTION_TYPES_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: GET_AUTOMATION_ACTION_TYPES_SUCCESSED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_AUTOMATION_ACTION_TYPES_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* watchActionList(action) {
  try {
    const response = yield call(automationActionSaga, action.payload);
    if (response.success) {
      yield put({
        type: GET_AUTOMATION_ACTION_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: GET_AUTOMATION_ACTION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_AUTOMATION_ACTION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchInputList(action) {
  try {
    const response = yield call(automationInputSaga, action.payload);
    if (response.success) {
      yield put({
        type: GET_AUTOMATION_INPUT_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: GET_AUTOMATION_INPUT_LIST_SUCCESSED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_AUTOMATION_INPUT_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchArtifactUserCreate(action) {
  const loaderHandle = showToastLoader('Creating artifact...')
  try {
    const response = yield call(automationArtifactCreateSaga, action.payload);
    if (response.success) {
      showToastSuccess('Artifact created', loaderHandle)
      yield put({
        type: CREATE_AUTOMATION_ARTIFACT_SUCCESSED,
        data: response,
      });
      yield put(automationArtifactList({ queryItem: action.query }));
    } else {
      showToastError('Artifact already exist', loaderHandle)
      yield put({
        type: CREATE_AUTOMATION_ARTIFACT_SUCCESSED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Artifact already exist', loaderHandle)
    yield put({
      type: CREATE_AUTOMATION_ARTIFACT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchUserCreate(action) {
  const loaderHandle = showToastLoader('Executing action...')
  try {
    const response = yield call(automationCreateSaga, action.payload);
    if (response.success) {
      showToastSuccess('Action executed', loaderHandle)
      if (action?.queryItem) {
        yield put(
          listAutomationManagement({ queryItem: action?.queryItem })
        );
      }
    } else {
      showToastError('Action execute failed', loaderHandle)
      yield put({
        type: CREATE_AUTOMATION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Action execute failed', loaderHandle)
    yield put({
      type: CREATE_AUTOMATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchArtifactImport(action) {
  const loaderHandle = showToastLoader('Importing artifact...')
  try {
    const response = yield call(automationImportSaga, action.payload);
    if (response.success) {
      showToastSuccess('Artifact imported', loaderHandle)
      yield put({
        type: AUTOMATION_IMPORT_SUCCESSED,
        data: response,
      });
      yield put(automationArtifactList({ queryItem: action?.query }));
    } else {
      showToastError('Artifact import failed', loaderHandle)
      yield put({
        type: AUTOMATION_IMPORT_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Artifact import failed', loaderHandle)
    yield put({
      type: AUTOMATION_IMPORT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchApprovalsUserCreate(action) {
  try {
    // yield put({ type: SHOW_LOADER });
    const response = yield call(listAutomationApprovalUser, action.payload);
    if (response.success) {
      yield put({
        type: AUTOMATION_APPROVAL_LIST_SUCCESSED,
        data: response,
      });
      // yield put({ type: HIDE_LOADER });
    } else {
      yield put({
        type: AUTOMATION_APPROVAL_LIST_SUCCESSED,
        data: null,
      });
    }
    // yield put({ type: HIDE_LOADER });
  } catch (err) {
    // yield put({ type: HIDE_LOADER });
    yield put({
      type: AUTOMATION_APPROVAL_LIST_FAILED,
      data: null,
    });
  }
}

export function* watchNotificationApprovals(action) {
  try {
    const response = yield call(listAutomationApprovalUser, action.payload);
    if (response.success) {
      yield put({
        type: NOTIFICATION_APPROVAL_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: NOTIFICATION_APPROVAL_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: NOTIFICATION_APPROVAL_LIST_FAILED,
      data: null,
    });
  }
}

export function* watchApproveRecord(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(approveRecordSaga, action.payload);
    yield put(
      automationApprovalsList({ queryItem: action?.queryItem })
    );
    yield put(
      notificationApprovalsList({ queryItem: 'aap_status=pending', isNotification: true })
    );
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
  }
}

export function* watchArtifactsList(action) {
  try {
    const response = yield call(listAutomationArtifactUser, action.payload);
    if (response.success) {
      yield put({
        type: AUTOMATION_ARTIFACT_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: AUTOMATION_ARTIFACT_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: AUTOMATION_ARTIFACT_LIST_FAILED,
      data: null,
    });
  }
}

export function* watchOccurenceList(action) {
  try {
    const response = yield call(occurenceListAPI, action.payload);
    if (response.success) {
      yield put({
        type: AUTOMATION_ARTIFACT_OCCURENCE_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: AUTOMATION_ARTIFACT_OCCURENCE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: AUTOMATION_ARTIFACT_OCCURENCE_LIST_FAILED,
      data: null,
    });
  }
}

export function* watchArtifactsTypeList(action) {
  try {
    // yield put({ type: SHOW_LOADER });
    const response = yield call(automationArtifactTypeSaga, action.payload);
    if (response.success) {
      yield put({
        type: AUTOMATION_ARTIFACT_TYPE_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({
        type: AUTOMATION_ARTIFACT_TYPE_LIST_SUCCESSED,
        data: null,
      });
    }
    // yield put({ type: HIDE_LOADER });
  } catch (err) {
    // yield put({ type: HIDE_LOADER });
    yield put({
      type: AUTOMATION_ARTIFACT_TYPE_LIST_FAILED,
      data: null,
    });
  }
}

export function* watchExecutionApplicationList(action) {
  try {
    const response = yield call(
      automationArtifactExecuteApplicationSaga,
      action.payload
    );
    if (response.success) {
      yield put({
        type: GET_AUTOMATION_EXECUTION_APPLICATION_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({
        type: GET_AUTOMATION_EXECUTION_APPLICATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_AUTOMATION_EXECUTION_APPLICATION_LIST_FAILED,
      data: null,
    });
  }
}

export function* watchExecutionActionList(action) {
  try {
    const response = yield call(
      automationArtifactExecuteActionSaga,
      action.payload
    );
    if (response.success) {
      yield put({
        type: GET_AUTOMATION_EXECUTION_ACTION_LIST_SUCCESSED,
        data: response.data,

      });
    } else {
      yield put({
        type: GET_AUTOMATION_EXECUTION_ACTION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_AUTOMATION_EXECUTION_ACTION_LIST_FAILED,
      data: null,
    });
  }
}

export function* watchExecutionUpdate(action) {
  const loaderHandle = showToastLoader('Executing artifact...')
  try {
    const response = yield call(ArtifactExecuteSaga, action.payload);

    if (response.success) {
      showToastSuccess('Artifact executed', loaderHandle)
      yield put({
        type: AUTOMATION_EXECUTION_SUCCESSED,
        data: response.data,
      });
    } else {
      showToastError('Artifact execute failed', loaderHandle)
      yield put({
        type: AUTOMATION_EXECUTION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Artifact execute failed', loaderHandle)
    yield put({
      type: AUTOMATION_EXECUTION_FAILED,
      data: null,
    });
  }
}
export function* watchBulkApprove(action) {
  const loaderHandle = showToastLoader('Approving action execution request...')
  try {
    const bulkApproveData = yield call(bulkApprove, action.payload);

    if (bulkApproveData.success === true) {
      showToastSuccess('Automation action execution approved', loaderHandle)
      yield put({ type: BULK_APPROVE_SUCCESSED, data: bulkApproveData });
      const response = yield call(listAutomationApprovalUser, action.payload.data);
      if (response.success) {
        yield put({ type: AUTOMATION_APPROVAL_LIST_SUCCESSED, data: response });
      } else {
        yield put({ type: AUTOMATION_APPROVAL_LIST_FAILED, data: null });
      }
    } else {
      showToastError('Records approve failed', loaderHandle)
      yield put({ type: BULK_APPROVE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Records approve failed', loaderHandle)
    yield put({
      type: BULK_APPROVE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchBulkDecline(action) {
  const loaderHandle = showToastLoader('Declining action execution request...')
  try {
    const bulkDeclineData = yield call(bulkDecline, action.payload);

    if (bulkDeclineData.success === true) {
      showToastSuccess('Automation action execution declined', loaderHandle)
      yield put({ type: BULK_DECLINE_SUCCESSED, data: bulkDeclineData });
      const response = yield call(listAutomationApprovalUser, action.payload.data);
      if (response.success) {
        yield put({ type: AUTOMATION_APPROVAL_LIST_SUCCESSED, data: response });
      } else {
        yield put({ type: AUTOMATION_APPROVAL_LIST_FAILED, data: null });
      }
    } else {
      showToastError('Records decline failed', loaderHandle)
      yield put({ type: BULK_DECLINE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Records decline failed', loaderHandle)
    yield put({
      type: BULK_DECLINE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGenerateReport(action) {
  const loaderHandle = showToastLoader('Generating report...')
  try {
    const response = yield call(generateReport, action.payload);
    if (response.size) {
      showToastSuccess('Report generated', loaderHandle)
      yield put({ type: AUTOMATION_GENERATE_REPORT_SUCCESSED, data: response });
    } else {
      showToastError('Report generation failed', loaderHandle)
      yield put({ type: AUTOMATION_GENERATE_REPORT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Report generation failed', loaderHandle)
    yield put({
      type: AUTOMATION_GENERATE_REPORT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export default function* watcher() {
  yield takeLatest(AUTOMATION_LIST_REQUESTED, watchUsersList);
  yield takeLatest(AUTOMATION_DETAILS_LIST_REQUESTED, watchUsersDetails);
  yield takeLatest(AUTOMATION_UPDATE_REQUESTED, watchUpdateArtifact);
  yield takeLatest(DELETE_AUTOMATION_REQUESTED, watchUserDelete);
  yield takeLatest(CHANGE_STATUS_AUTOMATION_REQUESTED, watchChangeStatus);
  yield takeLatest(
    GET_AUTOMATION_APPLICATION_LIST_REQUESTED,
    watchApplicationList
  );
  yield takeLatest(
    GET_AUTOMATION_ACTION_TYPES_REQUESTED,
    watchActionTypesList
  );
  
  yield takeLatest(GET_AUTOMATION_ACTION_LIST_REQUESTED, watchActionList);
  yield takeLatest(GET_AUTOMATION_INPUT_LIST_REQUESTED, watchInputList);
  yield takeLatest(CREATE_AUTOMATION_REQUESTED, watchUserCreate);
  yield takeLatest(AUTOMATION_IMPORT_REQUESTED, watchArtifactImport);
  yield takeLatest(AUTOMATION_ARTIFACT_LIST_REQUESTED, watchArtifactsList);
  yield takeLatest(
    AUTOMATION_ARTIFACT_OCCURENCE_LIST_REQUESTED,
    watchOccurenceList
  );
  yield takeLatest(AUTOMATION_ARTIFACT_DELETE_REQUESTED, watchArtifactDelete);
  yield takeLatest(
    AUTOMATION_ARTIFACT_TYPE_LIST_REQUESTED,
    watchArtifactsTypeList
  );
  yield takeLatest(
    CREATE_AUTOMATION_ARTIFACT_REQUESTED,
    watchArtifactUserCreate
  );
  yield takeLatest(
    AUTOMATION_APPROVAL_LIST_REQUESTED,
    watchApprovalsUserCreate
  );
  yield takeLatest(
    NOTIFICATION_APPROVAL_LIST_REQUESTED,
    watchNotificationApprovals
  );
  yield takeLatest(
    AUTOMATION_RECORD_APPROVE_REQUESTED,
    watchApproveRecord
  );
  yield takeLatest(
    GET_AUTOMATION_EXECUTION_APPLICATION_LIST_REQUESTED,
    watchExecutionApplicationList
  );
  yield takeLatest(
    GET_AUTOMATION_EXECUTION_ACTION_LIST_REQUESTED,
    watchExecutionActionList
  );
  yield takeLatest(AUTOMATION_EXECUTION_REQUESTED, watchExecutionUpdate);
  yield takeLatest(BULK_APPROVE_REQUESTED, watchBulkApprove);
  yield takeLatest(BULK_DECLINE_REQUESTED, watchBulkDecline);
  yield takeLatest(GET_APPROVAL_BY_ID_REQUESTED, watchApprovalDetails);
  yield takeLatest(AUTOMATION_GENERATE_REPORT_REQUESTED, watchGenerateReport);

}
