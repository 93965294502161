import { React } from 'react';
import { map, pull, isInteger, intersection, isArray } from 'lodash';
import Tag from '../UploadedItem';
import {
  TextInputWrapper,
  TextInputDiv,
  SelectInput,
  ErrorDiv,
  LabelDiv,
  Label,
  StyledDiv,
  StyledPlusIcon,
  StyledSpan
} from './StyledComponents';
import './index.css';
import PlusIcon from '../../assets/svgIcon/plusIcon';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const SelectBox = props => {
  const {
    errorMessage,
    isRequired,
    setHightAuto,
    type,
    mode,
    className,
    placeholder,
    name,
    value,
    label,
    group,
    onInputChange,
    onBlur,
    touched,
    id,
    removeChildData,
    width,
    options,
    disabled,
    loading = false,
    isHide,
    onSelect,
    noMargin,
    padding,
    defaultValue,
    isSuffix,
    groupList,
    separatedGroup,
    isDeselect,
    maxTagCount,
    handleKeyDown,
    shouldEmptyValue,
    allowClear,
    onClear,
    onSearch,
    isFullWidth,
    autoFocus,
    isBorder,
    helperText
  } = props;

  let tempOptions = (options && Array.isArray(options)) ? [...options] : [];

  let values = value;
  if (isDeselect) {
    if (group && groupList && separatedGroup) {
      tempOptions.unshift({
        key: '',
        value: '',
        label: '',
        GroupLabel: groupList[0]?.groupName
      });
    }
    else tempOptions.unshift({
      key: '',
      value: '',
      label: '',
    });
  }

  if (isArray(value)) {
    let arr = tempOptions.map(op => op?.value) || [];
    let v = intersection(value, arr);
    values = v;
  }

  if (isInteger(value) && !shouldEmptyValue &&
    tempOptions.filter(data => data?.key === value).length === 0) {
    values = '';
  }

  if (mode !== 'tags' && mode !== 'multiple'
    && value && !shouldEmptyValue
    && tempOptions.filter(data => data?.value === value).length === 0) {
    values = '';
  }

  const onSelectData = (name, val) => {
    if (onSelect !== undefined) {
      onSelect(name, val);
    }
  };

  function renderData() {
    let renderItem = (
      <StyledDiv className={className} padding={padding} isFullWidth={isFullWidth} isBorder={isBorder}>
        <TextInputWrapper width={width} noMargin={noMargin}>
          {label ? (
            <LabelDiv>
              <Label>{label} {isRequired && <StyledSpan> * </StyledSpan>} {helperText && <div className='helper-text'>
                <Tooltip title={helperText || ''}>
                  <InfoCircleOutlined/>
                </Tooltip>
              </div>}</Label>
            </LabelDiv>
          ) : null}
          <TextInputDiv>
            <SelectInput
              showArrow
              showSearch={tempOptions}
              onKeyDown={handleKeyDown}
              loading={loading}
              width={width}
              onClear={onClear}
              id={id}
              type={type}
              allowClear={allowClear || false}
              mode={mode}
              autoFocus={autoFocus || false}
              name={name}
              onSearch={onSearch || (() => { })}
              maxTagCount={maxTagCount || null}
              suffixIcon={
                isSuffix &&
                <StyledPlusIcon>
                  <PlusIcon />
                </StyledPlusIcon>
              }
              value={mode !== 'tags' ? values : value}
              onChange={(val, option) => {
                onInputChange(name, val, option);
                if (removeChildData === 'incCommentIngest') {
                  onInputChange(val);
                }
              }}
              onBlur={onBlur}
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              getPopupContainer={trigger => trigger.parentNode.parentNode}
              onSelect={val => onSelectData(name, val)}
              tagRender={props => {
                return (
                  <Tag
                    name={props.label}
                    margin="5px 5px"
                    padding="8px 12px"
                    height={setHightAuto ? 'auto' : 28}
                    disabled={props?.disabled ? props.disabled : disabled}
                    onRemove={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onInputChange(name, pull(value, props.value));
                      if (removeChildData === 'Artifacts') {
                        onInputChange(`Artifact_${props.value}`, '')
                      }
                      if (removeChildData === 'incCommentIngest') {
                        onInputChange(pull(value, props.value));
                      }
                    }}
                  />
                );
              }}
              placeholder={placeholder || 'Select'}
              disabled={disabled}
              defaultValue={defaultValue}
            >
              {(mode !== 'multiple' && mode !== 'tags') &&
                <SelectInput.Option
                  key={''}
                  value={''}
                  disabled='true'
                  Seletced='true'
                  hidden='true'
                >
                  {'Select'}

                </SelectInput.Option>

              }
              {group
                ? map(groupList, (group, index) => {
                  return (
                    <SelectInput.OptGroup label={!separatedGroup ? group.groupName : index === 0 ? '' : <hr />} key={index}>
                      {map(tempOptions, (option, index) => {
                        if (option.GroupLabel === group.groupName) {
                          return (
                            <SelectInput.Option
                              key={option.key}
                              value={option.value}
                              disabled={option.disabled}
                              className={separatedGroup ? 'separatedGroupOption' : ''}
                            >
                              {option.label}
                            </SelectInput.Option>
                          );
                        }
                        return null;
                      })}
                    </SelectInput.OptGroup>
                  );
                })
                : map(tempOptions, (option, index) => {
                  return (
                    <SelectInput.Option
                      key={option.key}
                      value={option.value}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </SelectInput.Option>
                  );
                })}
            </SelectInput>
          </TextInputDiv>
        </TextInputWrapper>
        {touched && errorMessage ? <ErrorDiv>{errorMessage}</ErrorDiv> : null}
      </StyledDiv>
    );

    renderItem = isHide === true ? <></> : renderItem;
    return renderItem;
  }
  return <>{renderData()}</>;
};

SelectBox.defaultProps = {
  group: false,
};
export default SelectBox;
