import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    SHOW_LOADER,
    HIDE_LOADER,
    VULNERABILITY_MANAGEMENT_GET_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_GET_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED,
    GET_VULNERABLITY_MANAGEMENT_BY_ID_REQUESTED,
    GET_VULNERABLITY_MANAGEMENT_BY_ID_SUCCESSED,
    GET_VULNERABLITY_MANAGEMENT_BY_ID_FAILED,
    VULNERABILITY_EXPORT_PDF_LIST_SUCCESSED,
    VULNERABILITY_EXPORT_PDF_LIST_FAILED,
    VUNERABILITY_EXPORT_EXCEL_LIST_SUCCESSED,
    VUNERABILITY_EXPORT_EXCEL_LIST_FAILED,
    VUNERABILITY_EXPORT_EXCEL_LIST_REQUESTED,
    VULNERABILITY_EXPORT_PDF_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_DELETE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_DELETE_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_DELETE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_FAILED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_REQUESTED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_BULK_UPDATE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_BULK_UPDATE_FAILED,
    VULNERABILITY_MANAGEMENT_BULK_UPDATE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ATTACK_TYPE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ATTACK_TYPE_FAILED,
    VULNERABILITY_MANAGEMENT_ASSET_TYPE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSET_TYPE_FAILED,
    VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_SEVERITY_SUCCESSED,
    VULNERABILITY_MANAGEMENT_SEVERITY_FAILED,
    VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_FAILED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_FAILED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_REQUESTED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ADD_ASSET_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ADD_ASSET_FAILED,
    VULNERABILITY_MANAGEMENT_ADD_ASSET_REQUESTED,
    VULNERABILITY_MANAGEMENT_DELETE_ASSET_REQUESTED,
    VULNERABILITY_MANAGEMENT_DELETE_ASSET_SUCCESSED,
    VULNERABILITY_MANAGEMENT_DELETE_ASSET_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_SUCCESSED,
    VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED,
    UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_REQUESTED,
    UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_SUCCESSED,
    UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_FAILED,
    ADD_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED,
    ADD_TASK_VULNERABILITY_MANAGEMENT_FAILED,
    ADD_TASK_VULNERABILITY_MANAGEMENT_REQUESTED,
    VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED,
    VIEW_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED,
    VIEW_TASK_VULNERABILITY_MANAGEMENT_FAILED,
    VULNERABILITY_MANAGEMENT_COMMENT_SUCCESSED,
    VULNERABILITY_MANAGEMENT_COMMENT_FAILED,
    VULNERABILITY_MANAGEMENT_COMMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_FAILED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_SUCCESSED,
    VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_FAILED,
    VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_REQUESTED,
    VULNERABILITY_TASK_CATEGORIES_SUCCESSED,
    VULNERABILITY_TASK_CATEGORIES_FAILED,
    VULNERABILITY_TASK_CATEGORIES_REQUESTED,
    VULNERABILITY_DELETE_ATTACHMENT_REQUESTED,
    VULNERABILITY_DELETE_ATTACHMENT_SUCCESSED,
    VULNERABILITY_DELETE_ATTACHMENT_FAILED,
    VULNERABILITY_MANAGEMENT_OPEN_CASES_REQUESTED,
    VULNERABILITY_MANAGEMENT_OPEN_CASES_SUCCESSED,
    VULNERABILITY_MANAGEMENT_OPEN_CASES_FAILED,
    VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_CASE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_CASE_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_UPDATE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_UPDATE_FAILED,
    VULNERABILITY_MANAGEMENT_UPDATE_REQUESTED,
    VULNERABILITY_VULNERABILITY_ASSET_SUCCESSED,
    VULNERABILITY_VULNERABILITY_ASSET_FAILED,
    VULNERABILITY_VULNERABILITY_ASSET_REQUESTED,
    VULNERABILITY_SEARCH_BY_NAME_ASSET_REQUESTED,
    VULNERABILITY_SEARCH_BY_NAME_ASSET_SUCCESSED,
    VULNERABILITY_SEARCH_BY_NAME_ASSET_FAILED,
    ASSESSMENT_TEMPLATE_CREATE_FAILED,
    ASSESSMENT_TEMPLATE_CREATE_REQUESTED,
    ASSESSMENT_TEMPLATE_CREATE_SUCCESSED,
    VULNERABILITY_TASK_DEPARTMENT_REQUESTED,
    VULNERABILITY_TASK_DEPARTMENT_SUCCESSED,
    VULNERABILITY_TASK_DEPARTMENT_FAILED,
    VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_NAME_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_NAME_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_CONTENT_SUCCESSED,
    VULNERABILITY_MANAGEMENT_CONTENT_FAILED,
    VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED,
    VULNERABILITY_MANAGEMENT_EDIT_SEARCH_SUCCESSED,
    VULNERABILITY_MANAGEMENT_EDIT_SEARCH_FAILED,
    VULNERABILITY_UPDATE_ASSESSMENT_SUCCESSED,
    VULNERABILITY_UPDATE_ASSESSMENT_FAILED,
    VULNERABILITY_UPDATE_ASSESSMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_FAILED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_FAILED,
    DOWNLOAD_XML_NESSUS_FILE,
    ASSESSMENT_DUPLICATE_REQUESTED,
    ASSESSMENT_DUPLICATE_SUCCESSED,
    ASSESSMENT_DUPLICATE_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED,
} from '../../constants/actionTypes';
import {
    vulnerabilityListAPI,
    vulnerabilityByIdAPI,
    vulnerabilityAssessmentListAPI,
    vulnerabilityListDeleteAPI,
    vulnerabilityAssessmentListDeleteAPI,
    generateReportValAssessmentAPI,
    templateListAssessmentAPI,
    exportExcel,
    exportPDF,
    bulkUpdateValnerabilityAPI,
    attackTypeListVulnerability,
    assetListVulnerability,
    severityListVulnerability,
    vulnerabilityLogList,
    initiatemanualAssessment,
    initiatemanualAssessmentuUpdate,
    vulnerabilitylistUpdate,
    vulnerabilityAddAsset,
    vulnerabilityAssetDelete,
    vulnerabilityAssessmentApplicationList,
    vulnerabilityAssessmentActionList,
    executeInitiateManualActionList,
    deleteMultiValnerabilityActionList,
    getValnerabilityAssessmentsAPI,
    addAssessmentVulnerabilityAPI,
    deleteAssessmentVulnerabilityAPI,
    importAssessmentAPI,
    addTaskVulnerabilityManagement,
    viewTaskVulnerabilityManagement,
    vulnerabilitiesComment,
    viewTaskVulnerabilityUploadNessus,
    vulnerabilityTemplateType,
    vulnerabilityTaskCategories,
    vulnerabilityDeleteAttachment,
    updatePartialVulnerabilityManagementApi,
    vulnerabilityCreateCases,
    vulnerabilityCaseList,
    vulnerabilityAsset,
    vulnerabilitySearchAsset,
    templateCreateAssessmentAPI,
    vulnerabilityTaskDepartments,
    vulnerabilityManagementNameList,
    vulnerabilityContent,
    vulnerabilityCveSearch,
    vulnerabilityUpdateAssessment,
    importedVulnerabilityAssessmentListAPI,
    importedVulnerabilityAssessmentAPI,
    importedAssessmentListDeleteAPI,
    downloadFile,
    duplicateAssessmentsaga,
    vulnerabilityAssessmentSourceFilterList
} from '../../api/vulnerabilityManagement';
import { assessmentListAction, getVulnerabilityDetail, vulnerabilityAssetRequest, vulnerabilityListAction, importedAssessmentListAction, vulnerabilityTemplateCreate, vulnerabilityAssessmentFilterList } from '../../actions/vulnerabilityManagement';
import { showToastLoader, showToastSuccess, showToastError, dismissToastLoader } from '../../utils/toasts';

const getResponseMessage = (module) => {
    switch (module) {
        case 'threat':
            return 'Threat';
        case 'impact':
            return 'Impact';
        case 'solution':
            return 'Solution';
        case 'evidence':
            return 'Evidence';
        case 'management-feedback':
            return 'Management feedback';
        default:
            return 'Vulnerability';
    }
}

export function* watchVulnerabilityList(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(vulnerabilityListAPI, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_GET_LIST_SUCCESSED,
                data: response
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_GET_LIST_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchVulnerabilityDeleteList(action) {
    const loaderHandle = showToastLoader('Deleting vulnerability...')
    try {
        const response = yield call(vulnerabilityListDeleteAPI, action.id);
        if (response.success) {
            showToastSuccess('Vulnerability deleted', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_DELETE_LIST_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        showToastError('Vulnerability delete failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_DELETE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchVulnerabilityUpdateList(action) {

    const message = getResponseMessage(action.module)
    const loaderHandle = showToastLoader(`Updating ${message.toLowerCase()}...`)

    try {
        const response = yield call(vulnerabilitylistUpdate, action.id, action.payload);
        if (response.success) {
            showToastSuccess(`${message} updated`, loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_UPDATE_SUCCESSED,
                data: response
            });
            yield put(getVulnerabilityDetail({ id: action?.id }));
            if (action?.isDetail) {
                yield put(getVulnerabilityDetail({ id: action?.id }));
            }
            if (action?.assessmentId) {
                yield put(vulnerabilityAssetRequest(action.assessmentId));
            }
            if (action?.isDetail && typeof (action?.isDetail) == 'function') action?.isDetail({ isSuccess: true });
        }
        else {
            showToastError('Vulnerability with same details already exist', loaderHandle)
            if (action?.isDetail && typeof (action?.isDetail) == 'function') action?.isDetail({ isSuccess: false });
        }
    } catch (err) {
        showToastError('Vulnerability with same details already exist', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_UPDATE_FAILED,
            data: err?.response?.data?.data,
        });
        if (action?.isDetail && typeof (action?.isDetail) == 'function') action?.isDetail({ isSuccess: false });
    }
}

export function* watchBulkUpdate(action) {
    const loaderHandle = showToastLoader('Updating records...')
    try {
        const response = yield call(bulkUpdateValnerabilityAPI, action.payload);
        if (response.success) {
            showToastSuccess('Records updated', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_BULK_UPDATE_SUCCESSED,
                data: response?.data,
            });
            yield put(
                vulnerabilityListAction({ queryItem: action.query })
            );
        }
    } catch (err) {
        showToastError('Updating records failed', loaderHandle)
        yield put({ type: VULNERABILITY_MANAGEMENT_BULK_UPDATE_FAILED, data: err });
    }
}

export function* watchAttackType() {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(attackTypeListVulnerability);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ATTACK_TYPE_SUCCESSED,
                data: response.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ATTACK_TYPE_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchAssetType() {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(assetListVulnerability);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSET_TYPE_SUCCESSED,
                data: response.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSET_TYPE_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchSeverityList() {
    try {
        yield put({ type: HIDE_LOADER });
        const response = yield call(severityListVulnerability);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_SEVERITY_SUCCESSED,
                data: response.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_SEVERITY_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchApplicationList(source) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(vulnerabilityAssessmentApplicationList, source);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_SUCCESSED,
                data: response.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchSourceFilterList(source) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(vulnerabilityAssessmentSourceFilterList, source);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_SUCCESSED,
                data: response.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchVulnerabilityActionList(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(vulnerabilityAssessmentActionList, action.id);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_SUCCESSED,
                data: response.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchVulnerabilityById(action) {
    try {
        const response = yield call(vulnerabilityByIdAPI, action.payload);
        if (response.success) {
            yield put({
                type: GET_VULNERABLITY_MANAGEMENT_BY_ID_SUCCESSED,
                data: response.data
            });
        }
    } catch (err) {
        yield put({
            type: GET_VULNERABLITY_MANAGEMENT_BY_ID_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchPDFExport(action) {
    const loaderHandle = showToastLoader('Downloading PDF...')
    try {
        const response = yield call(exportPDF, action?.payload);
        if (response.size) {
            showToastSuccess('PDF downloaded', loaderHandle)
            yield put({ type: VULNERABILITY_EXPORT_PDF_LIST_SUCCESSED, data: response?.data });
        }
    } catch (err) {
        showToastError('Downloading PDF failed', loaderHandle)
        yield put({
            type: VULNERABILITY_EXPORT_PDF_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// export function* watchExcelExport(action) {
//     const loaderHandle = showToastLoader('Downloading excel...')
//     try {
//         const response = yield call(exportExcel, action.payload);
//         if (response.size) {
//             showToastSuccess('Excel downloaded', loaderHandle)
//             yield put({ type: VUNERABILITY_EXPORT_EXCEL_LIST_SUCCESSED, data: response?.data });
//         }
//         else {
//             showToastError('Downloading excel failed', loaderHandle)
//             yield put({
//                 type: VUNERABILITY_EXPORT_EXCEL_LIST_FAILED,
//                 data: null,
//             });
//         }
//     } catch (err) {
//         showToastError('Downloading excel failed', loaderHandle)
//         yield put({
//             type: VUNERABILITY_EXPORT_EXCEL_LIST_FAILED,
//             data: err?.response?.data?.data,
//         });
//     }
// }

export function* watchVulnerabilityAddAsset(action) {
    const loaderHandle = showToastLoader('Adding asset...')

    try {
        const response = yield call(vulnerabilityAddAsset, action.payload);
        if (response.success) {
            showToastSuccess('Asset added', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ADD_ASSET_SUCCESSED,
                data: response
            });
            yield put(vulnerabilityAssetRequest(action.payload.vua_assesment_id, 1))
            if (action.payload?.onComplete) action.payload?.onComplete();
        } else {
            showToastError(response?.data?.message, loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ADD_ASSET_FAILED,
                data: response?.data?.message,
            });
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_ADD_ASSET_FAILED,
            data: err?.response?.data
        });
    }
}

export function* watchVulnerabilityAssessmentList(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(vulnerabilityAssessmentListAPI, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_SUCCESSED,
                data: response
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}
export function* watchImportedVulnerabilityAssessmentList(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(importedVulnerabilityAssessmentListAPI, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_SUCCESSED,
                data: response
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}
export function* watchImportedVulnerabilityAssessment(action) {
    const loaderHandle = showToastLoader('Loading please wait...')

    try {
        const response = yield call(importedVulnerabilityAssessmentAPI, action.payload);
        if (response.success) {
            yield put({

                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_SUCCESSED,
                data: response?.data
            });
            action.history.push({
                pathname: '/vulnerabilityManagement/assessments/import/mapFields',
                state: { docTitle: 'Assessment | Import' }
            });
            dismissToastLoader(loaderHandle)
        }
        // else{
        //     showToastError(`failed`, loaderHandle)
        // }

    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_FAILED,
            data: err?.response?.data?.data,
        });
        showToastError(`failed`, loaderHandle)
    }
}

export function* watchVulnerabilityAssetDeleteRequest(action) {
    const loaderHandle = showToastLoader('Deleting asset...')
    try {
        const response = yield call(vulnerabilityAssetDelete, action.payload);
        if (response.success) {
            showToastSuccess('Asset deleted', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_DELETE_ASSET_SUCCESSED,
                data: response
            });
            yield put(vulnerabilityAssetRequest(action.payload.asses_id));
        }
    } catch (err) {
        showToastError('Deleting asset failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_DELETE_ASSET_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchVulnerabilityExecuteList(action) {
    const loaderHandle = showToastLoader('Initiating scan ...')
    try {
        const response = yield call(executeInitiateManualActionList, action.payload);
        if (response.success) {
            showToastSuccess('Scan initiated', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        showToastError('Scan initiated failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchVulnerabilityAssessmentDeleteList(action) {
    const loaderHandle = showToastLoader('Deleting vulnerability assessment...')
    try {
        const response = yield call(vulnerabilityAssessmentListDeleteAPI, action.id);
        if (response.success) {
            showToastSuccess('Vulnerability assessment deleted', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_SUCCESSED,
                data: response
            });
            yield put(assessmentListAction({ queryItem: action.query }));
        }
    } catch (err) {
        showToastError('Deleting vulnerability assessment failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchVulnerabilityImportedAssessmentDeleteList(action) {
    const loaderHandle = showToastLoader('Deleting imported assessment...')
    try {
        const response = yield call(importedAssessmentListDeleteAPI, action.id);
        if (response.success) {
            showToastSuccess('Imported assessment deleted', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_SUCCESSED,
                data: response
            });
            yield put(importedAssessmentListAction({ queryItem: action.query }));
        }
    } catch (err) {
        showToastError('Deleting imported assessment failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchGenerateTemplate(action) {
    const loaderHandle = showToastLoader('Generating report...')
    try {
        const response = yield call(generateReportValAssessmentAPI, action.payload);
        if (response.size) {
            showToastSuccess('Report generated', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        showToastError('Generating template failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchTemplateList(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(templateListAssessmentAPI, action.id);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_SUCCESSED,
                data: response?.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchAssessmentCreateTemplate(action) {
    const loaderHandle = showToastLoader('Creating template...')
    try {
        const response = yield call(templateCreateAssessmentAPI, action.payload);
        if (response.success) {
            yield put({ type: ASSESSMENT_TEMPLATE_CREATE_SUCCESSED, data: response?.data });
            showToastSuccess(`Template created`, loaderHandle)
            yield put(vulnerabilityTemplateCreate());
        } else {
            yield put({ type: ASSESSMENT_TEMPLATE_CREATE_FAILED, data: null });
        }
    } catch (err) {
        yield put({ type: ASSESSMENT_TEMPLATE_CREATE_FAILED, data: err?.response?.data?.data });
        showToastError(`${err?.response?.data?.data}`, loaderHandle)
    }
}

export function* watchAssessmentLogList(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(vulnerabilityLogList, action.id);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_SUCCESSED,
                data: response?.data
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}

export function* watchInitialManualAssessment(action) {
    const loaderHandle = showToastLoader('Creating assessment...')
    try {
        const response = yield call(initiatemanualAssessment, action.payload);
        if (response.success) {
            showToastSuccess('Assessment created', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_SUCCESSED,
                data: response?.data
            });
            yield put(assessmentListAction({ queryItem: action.query }));
            if (action?.callback) action.callback({ isSuccess: true });
        }
    } catch (err) {
        showToastError('Creating assessment failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_FAILED,
            data: err?.response?.data?.data,
        });
        if (action?.callback) action.callback({ isSuccess: false });
    }
}

export function* watchInitialManualAssessmentUpdate(action) {
    const loaderHandle = showToastLoader('Updating assessment...')
    try {
        const response = yield call(initiatemanualAssessmentuUpdate, action.id, action.payload);
        if (response.success) {
            showToastSuccess('Assessment updated', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_SUCCESSED,
                data: response?.data
            });
            yield put(assessmentListAction({ queryItem: action.query }));
            if (action?.callback) action.callback({ isSuccess: true });
        }
    } catch (err) {
        showToastError('Updating assessment failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_FAILED,
            data: err?.response?.data?.data,
        });
        if (action?.callback) action.callback({ isSuccess: false });
    }
}

export function* watchDeleteMultiDeleteVulnerability(action) {
    try {
        const response = yield call(deleteMultiValnerabilityActionList, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchGetVulnerability(action) {
    try {
        const response = yield call(getValnerabilityAssessmentsAPI, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_SUCCESSED,
                data: response?.data
            });
        }
    } catch (err) {
        showToastSuccess('Something went wrong')
        yield put({
            type: VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchAddAssessmentVulnerabilty(action) {
    const loaderHandle = showToastLoader('Adding vulnerability...')
    try {
        const response = yield call(addAssessmentVulnerabilityAPI, action.payload);
        if (response.success) {
            showToastSuccess('Vulnerability added', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_SUCCESSED,
                data: response?.data
            });
            yield put(vulnerabilityAssetRequest(action.assessmentId));
            if (action?.callback) action.callback({ isSuccess: true });
        }
    } catch (e) {
        showToastError(e?.response?.data?.data?.message || 'Adding vulnerability failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_FAILED,
            data: e?.response?.data?.data,
        });
        if (action?.callback) action.callback({ isSuccess: false });
    }
}

export function* watchImportAssessment(action) {
    const loaderHandle = showToastLoader('Importing file...')
    try {
        const response = yield call(importAssessmentAPI, action.payload);
        if (response.success) {
            showToastSuccess('File imported', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_SUCCESSED,
                data: response?.data
            });
            const response2 = yield call(importedVulnerabilityAssessmentListAPI, action.payload);
            if (response2.success) {
                yield put({ type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_SUCCESSED, data: response2 });
            } else {
                yield put({ type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_FAILED, data: null });
            }
            action.history.push('/vulnerabilityManagement/importedAssessments')

        }
    } catch (err) {

        if (err?.response?.status == 417) {
            showToastError('File must be xml', loaderHandle)
        }
        else {
            showToastError('File import failed', loaderHandle)
        }
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchDeleteAssessmentVulnerabilty(action) {
    const loaderHandle = showToastLoader('Deleting vulnerability...')
    try {
        const response = yield call(deleteAssessmentVulnerabilityAPI, action.payload);
        if (response.success) {
            showToastSuccess('Vulnerability deleted', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_SUCCESSED,
                data: response?.data
            });
            yield put(vulnerabilityAssetRequest(action.payload.asses_id));
        } else {
            showToastError('Vulnerability delete failed', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_FAILED,
                data: response?.response?.data?.data,
            });
        }
    } catch (e) {
        showToastError('Vulnerability delete failed', loaderHandle)
        yield put({
            type: VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_FAILED,
            data: e?.response?.data?.data,
        });
    }
}

export function* watchUpdatePartialVulnerability(action) {
    const loaderHandle = showToastLoader('Updating vulnerability...')
    try {
        const response = yield call(updatePartialVulnerabilityManagementApi, action?.payload);
        if (response.success) {
            showToastSuccess('Vulnerability updated', loaderHandle)
            yield put({
                type: UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_SUCCESSED,
                data: response?.data,
            });
        }
    } catch (err) {
        showToastError('Vulnerability delete failed', loaderHandle)
        yield put({ type: UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_FAILED, data: err });
    }
}

export function* watchAddTaskVulnerability(action) {
    try {
        const response = yield call(addTaskVulnerabilityManagement, action?.payload);
        if (response.success) {
            yield put({
                type: ADD_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED,
                data: response.data,
            });
        }
    } catch (err) {
        yield put({ type: ADD_TASK_VULNERABILITY_MANAGEMENT_FAILED, data: err });
    }
}

export function* watchViewTaskVulnerability(action) {
    try {
        const response = yield call(viewTaskVulnerabilityManagement, action?.payload);
        if (response.success) {
            yield put({
                type: VIEW_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED,
                data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VIEW_TASK_VULNERABILITY_MANAGEMENT_FAILED, data: err });
    }
}

export function* watchAddCommentValnerability(action) {
    try {
        const response = yield call(vulnerabilitiesComment, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_COMMENT_SUCCESSED,
                data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_MANAGEMENT_COMMENT_FAILED, data: err });
    }
}

export function* watchValnerabilityNessusUpload(action) {
    const loaderHandle = showToastLoader('Importing...')
    try {
        const response = yield call(viewTaskVulnerabilityUploadNessus, action.payload);
        if (response.success) {
            showToastSuccess('Assessment imported', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_SUCCESSED,
                data: response?.data,

            })
            action.history.push('/vulnerabilityManagement/assessments')
        }
    } catch (err) {
        showToastError('Import failed', loaderHandle)
        yield put({ type: VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_FAILED, data: err });
    }
}

export function* watchTemplateTypeList() {

    try {
        const response = yield call(vulnerabilityTemplateType);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_SUCCESSED,
                data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_FAILED, data: err });
    }
}

// Task Categories
export function* watchTaskCategories(action) {
    try {
        const response = yield call(vulnerabilityTaskCategories);
        if (response.success) {
            yield put({
                type: VULNERABILITY_TASK_CATEGORIES_SUCCESSED,
                data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_TASK_CATEGORIES_FAILED, data: err });
    }
}

export function* watchTaskDepartments(action) {
    try {
        const response = yield call(vulnerabilityTaskDepartments);
        if (response.success) {
            yield put({
                type: VULNERABILITY_TASK_DEPARTMENT_SUCCESSED,
                data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_TASK_DEPARTMENT_FAILED, data: err });
    }
}

// Watch Delete Attachment
export function* watchDeleteAttachment(action) {
    try {
        const response = yield call(vulnerabilityDeleteAttachment, action.id, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_DELETE_ATTACHMENT_SUCCESSED,
                data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_DELETE_ATTACHMENT_FAILED, data: err });
    }
}

export function* watchCreateCases(action) {
    const loaderHandle = showToastLoader('Creating case...')
    try {

        const response = yield call(vulnerabilityCreateCases, action.payload.newCase.data);
        if (response.success) {
            showToastSuccess('Case created', loaderHandle)
            yield put({
                type: VULNERABILITY_MANAGEMENT_OPEN_CASES_SUCCESSED,
                data: action?.payload?.newCase?.data,
            })
            if (action?.payload?.newCase?.callback) {
                action?.payload?.newCase?.callback({ isSuccess: true });
            }
            yield put(getVulnerabilityDetail({ id: `${action?.payload?.newCase?.id}` }))
        }
        else {
            showToastError('Vulnerability case create failed', loaderHandle)
            yield put({ type: VULNERABILITY_MANAGEMENT_OPEN_CASES_FAILED, data: action?.payload?.newCase?.data });
            if (action?.payload?.newCase?.callback) {
                action?.payload?.newCase?.callback({ isSuccess: false });
            }
        }


    } catch (err) {

        showToastError('Vulnerability case create failed', loaderHandle)
        yield put({ type: VULNERABILITY_MANAGEMENT_OPEN_CASES_FAILED, data: err });
        if (action?.payload?.newCase?.callback) {
            action?.payload?.newCase?.callback({ isSuccess: false });
        }
    }
}

export function* watchvulnerabilityCasesList(action) {
    try {

        const response = yield call(vulnerabilityCaseList, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_CASE_LIST_SUCCESSED, data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_MANAGEMENT_CASE_LIST_FAILED, data: err });
    }
}

export function* watchvulnerabilityAssetRequest(action) {
    try {
        const response = yield call(vulnerabilityAsset, action.id, action.page);
        if (response.success) {
            yield put({
                type: VULNERABILITY_VULNERABILITY_ASSET_SUCCESSED, data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_VULNERABILITY_ASSET_FAILED, data: err });
    }
}

export function* watchvulnerabilitySearchByNameAsset(action) {
    try {
        const response = yield call(vulnerabilitySearchAsset, action.searchValue);
        if (response.success) {
            yield put({
                type: VULNERABILITY_SEARCH_BY_NAME_ASSET_SUCCESSED, data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_SEARCH_BY_NAME_ASSET_FAILED, data: err });
    }
}

export function* watchvulnerabilityNameList() {
    try {
        const response = yield call(vulnerabilityManagementNameList);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_NAME_LIST_SUCCESSED, data: response?.data,
            })
        }
    } catch (err) {
        yield put({ type: VULNERABILITY_MANAGEMENT_NAME_LIST_FAILED, data: err });
    }
}

//vulnerabilityContent vulnerabilityCveSearch
export function* watchvulnerabilityContentList(action) {
    try {
        const response = yield call(vulnerabilityContent, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_CONTENT_SUCCESSED, data: response?.data
            })
        }
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_CONTENT_FAILED, data: err
        })
    }
}

export function* watchvulnerabilityEditSearch(action) {
    try {
        const response = yield call(vulnerabilityCveSearch, action.payload);
        if (response.success) {
            yield put({
                type: VULNERABILITY_MANAGEMENT_EDIT_SEARCH_SUCCESSED, data: response?.data
            })
        }
    } catch (err) {
        yield put({
            type: VULNERABILITY_MANAGEMENT_EDIT_SEARCH_FAILED, data: err
        })
    }
}

export function* watchVulnerabilityUpdateAssessment(action) {
    const message = getResponseMessage(action.module)
    const loaderHandle = showToastLoader(`Updating ${message.toLowerCase()}...`)
    try {
        const response = yield call(vulnerabilityUpdateAssessment, action.id, action.assessmentId, action.assetId, action.payload);
        if (response.success) {
            showToastSuccess(`${message} updated`, loaderHandle)
            yield put({
                type: VULNERABILITY_UPDATE_ASSESSMENT_SUCCESSED,
                data: response
            });
            yield put(getVulnerabilityDetail({ id: action?.id }));
            if (action?.isDetail) {
                yield put(getVulnerabilityDetail({ id: action?.id }));
            }
            if (action?.assessmentId) {
                yield put(vulnerabilityAssetRequest(action.assessmentId));
            }
            if (action?.callback) action.callback({ isSuccess: true });
        }
        else {
            showToastError('Error', loaderHandle)
            if (action?.callback) action.callback({ isSuccess: false });
        }
    } catch (e) {
        showToastError(e?.response?.data?.data?.message || 'Updating vulnerability failed', loaderHandle)
        yield put({
            type: VULNERABILITY_UPDATE_ASSESSMENT_FAILED,
            data: e?.response?.data?.data,
        });
        if (action?.callback) action.callback({ isSuccess: false });
    }
}
export function* watchXmlNessusDownload(action) {
    try {
        yield put({ type: SHOW_LOADER });
        yield call(downloadFile, action.payload);
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({ type: HIDE_LOADER });
    }
}
export function* watchDuplicate(action) {
    const loaderHandle = showToastLoader('Duplicating assessment...')
    try {
        const response = yield call(duplicateAssessmentsaga, action.id);
        if (response.success) {
            showToastSuccess('Assessment duplicated', loaderHandle)
            yield put({ type: ASSESSMENT_DUPLICATE_SUCCESSED, data: response });

            const response2 = yield call(vulnerabilityAssessmentListAPI, action.query);
            if (response2.success) {
                yield put({ type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_SUCCESSED, data: response2 });
            } else {
                yield put({ type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_FAILED, data: null });
            }

            // yield put(vulnerabilityAssessmentListAPI({ queryItem: action.query }));
        } else {
            showToastError('Assessment duplicate failed', loaderHandle)
            yield put({ type: ASSESSMENT_DUPLICATE_FAILED, data: null });
        }
    } catch (err) {
        showToastError('Assessment duplicate failed', loaderHandle)
        yield put({
            type: ASSESSMENT_DUPLICATE_FAILED,
            data: err?.response?.data?.data,
        });
    }
}


export default function* watcher() {
    yield takeLatest(VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED, watchVulnerabilityList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_DELETE_LIST_REQUESTED, watchVulnerabilityDeleteList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED, watchAttackType);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED, watchAssetType);
    yield takeLatest(VULNERABILITY_MANAGEMENT_BULK_UPDATE_REQUESTED, watchBulkUpdate);
    yield takeLatest(VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED, watchSeverityList);
    yield takeLatest(GET_VULNERABLITY_MANAGEMENT_BY_ID_REQUESTED, watchVulnerabilityById);
    yield takeLatest(VULNERABILITY_EXPORT_PDF_LIST_REQUESTED, watchPDFExport);
    // yield takeLatest(VUNERABILITY_EXPORT_EXCEL_LIST_REQUESTED, watchExcelExport);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED, watchVulnerabilityAssessmentList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_REQUESTED, watchVulnerabilityAssessmentDeleteList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_REQUESTED, watchGenerateTemplate);
    yield takeLatest(VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED, watchTemplateList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED, watchAssessmentLogList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_REQUESTED, watchInitialManualAssessmentUpdate);
    yield takeLatest(VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_REQUESTED, watchInitialManualAssessment);
    yield takeLatest(VULNERABILITY_MANAGEMENT_UPDATE_REQUESTED, watchVulnerabilityUpdateList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ADD_ASSET_REQUESTED, watchVulnerabilityAddAsset);
    yield takeLatest(VULNERABILITY_MANAGEMENT_DELETE_ASSET_REQUESTED, watchVulnerabilityAssetDeleteRequest);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED, watchApplicationList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED, watchSourceFilterList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED, watchVulnerabilityActionList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_REQUESTED, watchVulnerabilityExecuteList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_REQUESTED, watchDeleteMultiDeleteVulnerability);
    yield takeLatest(VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED, watchGetVulnerability);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_REQUESTED, watchAddAssessmentVulnerabilty);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED, watchImportAssessment);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_REQUESTED, watchDeleteAssessmentVulnerabilty)
    yield takeLatest(UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_REQUESTED, watchUpdatePartialVulnerability)
    yield takeLatest(ADD_TASK_VULNERABILITY_MANAGEMENT_REQUESTED, watchAddTaskVulnerability)
    yield takeLatest(VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED, watchViewTaskVulnerability)
    yield takeLatest(VULNERABILITY_MANAGEMENT_COMMENT_REQUESTED, watchAddCommentValnerability)
    yield takeLatest(VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_REQUESTED, watchValnerabilityNessusUpload)
    yield takeLatest(VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED, watchTemplateTypeList)
    yield takeLatest(VULNERABILITY_TASK_CATEGORIES_REQUESTED, watchTaskCategories)
    yield takeLatest(VULNERABILITY_DELETE_ATTACHMENT_REQUESTED, watchDeleteAttachment)
    yield takeLatest(VULNERABILITY_MANAGEMENT_OPEN_CASES_REQUESTED, watchCreateCases)
    yield takeLatest(VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED, watchvulnerabilityCasesList);
    yield takeLatest(VULNERABILITY_VULNERABILITY_ASSET_REQUESTED, watchvulnerabilityAssetRequest);
    yield takeLatest(VULNERABILITY_SEARCH_BY_NAME_ASSET_REQUESTED, watchvulnerabilitySearchByNameAsset);
    yield takeLatest(ASSESSMENT_TEMPLATE_CREATE_REQUESTED, watchAssessmentCreateTemplate)
    yield takeLatest(VULNERABILITY_TASK_DEPARTMENT_REQUESTED, watchTaskDepartments)
    yield takeLatest(VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED, watchvulnerabilityNameList)
    yield takeLatest(VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED, watchvulnerabilityContentList)
    yield takeLatest(VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED, watchvulnerabilityEditSearch)
    yield takeLatest(VULNERABILITY_UPDATE_ASSESSMENT_REQUESTED, watchVulnerabilityUpdateAssessment);
    yield takeLatest(VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED, watchImportedVulnerabilityAssessmentList);
    yield takeLatest(VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED, watchImportedVulnerabilityAssessment);
    yield takeLatest(VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_REQUESTED, watchVulnerabilityImportedAssessmentDeleteList);
    yield takeLatest(DOWNLOAD_XML_NESSUS_FILE, watchXmlNessusDownload);
    yield takeEvery(ASSESSMENT_DUPLICATE_REQUESTED, watchDuplicate);
}
//watchvulnerabilityEditSearch
