import { put, call, takeLatest } from 'redux-saga/effects';
import {
    GET_REPORT_LIST_REQUESTED,
    GET_REPORT_LIST_SUCCESSED,
    GET_REPORT_LIST_FAILED,
    ON_REPORT_DUPLICATE_FAILED,
    ON_REPORT_DUPLICATE_SUCCESSED,
    ON_REPORT_DUPLICATE_REQUESTED,
    DELETE_REPORT_LIST_SUCCESSED,
    DELETE_REPORT_LIST_FAILED,
    DELETE_REPORT_LIST_REQUESTED,
    HIDE_LOADER,
    SHOW_LOADER,
    SET_FEEDBACK_ALERT,
} from '../../constants/actionTypes';
import { deleteReportAPI, duplicateReportAPI, getReportsListApi } from '../../api/reportsApi';

export function* watchGetReportsList(action) {
    try {
        const response = yield call(getReportsListApi, action.payload);
        if (response.success === true) {
            yield put({ type: GET_REPORT_LIST_SUCCESSED, data: response?.data });
        } else {
            yield put({ type: GET_REPORT_LIST_FAILED, data: response?.data });
        }
    } catch (err) {
        yield put({
            type: GET_REPORT_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* deleteReport(action) {
    try {
        const response = yield call(deleteReportAPI, action.id);
        if (response.success === true) {
            yield put({ type: DELETE_REPORT_LIST_SUCCESSED, data: response?.data });
        }
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'Delete Report successfully',
                feedbackType: 'success',
                module: "Report",
            },
        });
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'Something Went Wrong',
                feedbackType: 'success',
                module: "Report",
            },
        });
        yield put({
            type: DELETE_REPORT_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* duplicateReport(action) {
    yield put({ type: SHOW_LOADER });
    try {
        const response = yield call(duplicateReportAPI, action.payload);
        if (response.success === true) {
            yield put({ type: ON_REPORT_DUPLICATE_SUCCESSED, data: response?.data });
        }
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'Report Duplicate successfully',
                feedbackType: 'success',
                module: "Report",
            },
        });
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({ type: HIDE_LOADER });
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'something went wrong',
                feedbackType: 'success',
                module: "Report",
            },
        });
        yield put({
            type: ON_REPORT_DUPLICATE_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export default function* watcher() {
    yield takeLatest(GET_REPORT_LIST_REQUESTED, watchGetReportsList)
    yield takeLatest(DELETE_REPORT_LIST_REQUESTED, deleteReport)
    yield takeLatest(ON_REPORT_DUPLICATE_REQUESTED, duplicateReport)
}
