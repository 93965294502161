import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetUser } from '../actions/user';
import { userLogout } from '../actions/auth';

const PrivateRoute = ({
  component: Component,
  location,
  userStore,
  resetUser,
  userLogout,
  ...rest
}) => {

  useEffect(() => {
    if (userStore.hasErrors && userStore.hasErrors.code === 401) {
      localStorage.removeItem('AccessToken');
      resetUser();
      userLogout();
    }
  }, [userStore.hasErrors, resetUser, userLogout]);
   
  if (location.pathname === '/') {
    return <Redirect to="/dashboard" />;
  }

return <Route render={() => <Component {...rest} />} />;
};

const mapStateToProps = state => {
  return {
    userStore: state.userStore,
  };
};

const mapDispatchToProps = {
  userLogout,
  resetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
