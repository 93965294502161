import styled from 'styled-components';
import { Button } from 'antd';
import { Colors, Fonts } from '../../theme';

export const AppButton = styled(Button)`
  width: 100%;
  height: ${props => (props.height ? `${props.height}px` : '32px')} !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.primaryWhite} !important;
  background: ${Colors.primaryGreen};
  border-color: ${Colors.primaryGreen};
  &:disabled {
    background: ${Colors.primaryGreen};
    border-color: ${Colors.primaryGreen};
    opacity: 0.5;
  }
  &:hover{
    background: ${Colors.lightGreen};
    border-color: ${Colors.lightGreen};
  }
  &:focus{
    background: ${Colors.primaryGreen};
    border-color: ${Colors.primaryGreen};
  }
  ${props =>
    props.type === 'danger' &&
    `
        background-color: ${Colors.progressDangerStart}; 
        border-color:${Colors.progressDangerStart};
        &:hover{
           background: #D74444 !important;
            border-color: #D74444 !important;
         }
         &:focus{
          background-color: ${Colors.progressDangerStart}; 
          border-color:${Colors.progressDangerStart};
        }
          &:disabled {
             background: ${Colors.progressDangerStart};
              border-color:${Colors.progressDangerStart};
              opacity: 0.5;
          }
    `}

  ${props =>
    props.type === 'secondary' &&
    `
        background-color: ${Colors.transparent}; 
        border-color:${Colors.primaryWhite};
        &:hover {
          background: ${Colors.secondaryTransparent} !important;
          border-color: ${Colors.primaryWhite} !important;
         }
         &:focus{
          background-color: ${Colors.transparent}; 
          border-color:${Colors.primaryWhite};
        }
          &:disabled {
              background-color: ${Colors.transparent}; 
              border-color:${Colors.primaryWhite};
              opacity: 0.5;
          }
    `}
`;

export const StyledText = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  font-family: ${Fonts.type.robotoRegular};
  margin-left: 5px;
  margin-right: 5px;
  color: ${Colors.primaryWhite} !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.3rem;
  padding-left: 0.3rem;
`;

export const StyledDiv = styled.div`
  margin-left: 10px;
  align-items: center;
  display: flex;
  height: 12px;
  width: 12px;
`;
