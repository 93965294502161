import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { masterLogin } from '../../../actions/auth';
import {
      Container,
      Content,
      LogoImage,
      SpinnerContainer,
} from './StyledComponents';
import Logo from '../../../assets/images/logo.png';
import loaderImg from '../../../assets/images/loader.gif';

const MsspLogin = ({ masterLoginAction, masterLoginStore }) => {
      const history = useHistory();
      const location = useLocation();
      const [query, setQuery] = useState(location.search);

      let apiData = queryString.parse(query)

      useEffect(() => {
            masterLoginAction(apiData, history);
      }, apiData?.code);

      useEffect(() => {
            if (masterLoginStore?.error) {
                  history.push('/login');
            }
      }, [masterLoginStore?.error]);

      useEffect(() => {
            if (masterLoginStore?.isSuccess) {
                  const accessToken = localStorage.getItem('AccessToken');
                  if (accessToken !== null) {
                        history.push('/dashboard');
                  }
            }
      }, [masterLoginStore?.isSuccess]);

      return (
            <Container>
                  <Content>
                        <LogoImage src={Logo} />
                        <SpinnerContainer>
                              <img src={loaderImg} alt="Loader" />
                        </SpinnerContainer>
                  </Content>
            </Container>
      );
};


const mapStateToProps = state => {
      return {
            masterLoginStore: state.masterLoginStore,
      };
};

const mapDispatchToProps = dispatch => {
      return {
            masterLoginAction: (...data) => {
                  return dispatch(masterLogin(...data));
            },
      };
};
export default connect(mapStateToProps, mapDispatchToProps)(MsspLogin);
