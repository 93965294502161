import { put, call, takeLatest } from 'redux-saga/effects';
import { riskAssementListAPI, exportPDF, exportExcel, initiatorAPI, riskAssessmentScopeApi, riskassementupdateListAPI, riskassementdeleteListAPI, templateListRiskAssessmentAPI, generateReportRiskAssessmentAPI, importReportRiskAssessmentAPI, riskassementMarkList } from '../../api/riskAssessmentApi';
import { RISK_ASSESMENT_GET_LIST_REQUESTED, RISK_ASSESMENT_GET_LIST_SUCCESSED, RISK_ASSESMENT_GET_LIST_FAILED, SET_FEEDBACK_ALERT, SHOW_LOADER, RISK_ASSESMENT_EXPORT_EXEL_REQUESTED, RISK_ASSESMENT_EXPORT_EXEL_SUCCESSED, RISK_ASSESMENT_EXPORT_PDF_SUCCESSED, RISK_ASSESMENT_EXPORT_PDF_FAILED, RISK_ASSESMENT_EXPORT_EXEL_FAILED, RISK_ASSESMENT_EXPORT_PDF_REQUESTED, RISK_ASSESMENT_INITIATOR_LIST_SUCCESSED, RISK_ASSESMENT_INITIATOR_LIST_FAILED, RISK_ASSESMENT_SCOPE_LIST_REQUESTED, RISK_ASSESMENT_SCOPE_LIST_FAILED, RISK_ASSESMENT_SCOPE_LIST_SUCCESSED, RISK_ASSESMENT_RISK_REGISTER_LIST_REQUESTED, RISK_ASSESMENT_RISK_REGISTER_LIST_SUCCESSED, RISK_ASSESMENT_RISK_REGISTER_LIST_FAILED, RISK_ASSESMENT_DELETE_LIST_SUCCESSED, RISK_ASSESMENT_DELETE_LIST_FAILED, RISK_ASSESMENT_DELETE_LIST_REQUESTED, RISK_ASSESMENT_UPDATE_LIST_REQUESTED, RISK_ASSESMENT_UPDATE_LIST_SUCCESSED, RISK_ASSESMENT_UPDATE_LIST_FAILED, RISK_ASSESMENT_TEMPLATE_LIST_REQUESTED, RISK_ASSESMENT_TEMPLATE_LIST_SUCCESSED, RISK_ASSESMENT_TEMPLATE_LIST_FAILED, RISK_ASSESMENT_GENERATE_REPORTS_SUCCESSED, RISK_ASSESMENT_GENERATE_REPORTS_FAILED, RISK_ASSESMENT_GENERATE_REPORTS_REQUESTED, RISK_ASSESMENT_LIST_MARK_SUCCESSED, RISK_ASSESMENT_LIST_MARK_FAILED, RISK_ASSESMENT_LIST_MARK_REQUESTED, HIDE_LOADER } from '../../constants/actionTypes';

const moduleName = 'Risk Management';

export function* watchRiskAssesmentList(action) {
    try {
        const response = yield call(riskAssementListAPI, action.payload);
        if (response.success === true) {
            yield put({
                type: RISK_ASSESMENT_GET_LIST_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_GET_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchRiskAssesmentDelete(action) {
    try {
        const response = yield call(riskassementdeleteListAPI, action.id);
        if (response.success === true) {
            yield put({
                type: RISK_ASSESMENT_DELETE_LIST_SUCCESSED,
                data: response
            });
            yield put({
                type: SET_FEEDBACK_ALERT,
                payload: {
                    feedbackMessage: 'Risk Deleted Successfully',
                    feedbackType: 'error',
                    module: moduleName,
                },
            });
        }
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'Something went wrong',
                feedbackType: 'error',
                module: moduleName,
            },
        });
        yield put({
            type: RISK_ASSESMENT_DELETE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchRiskAssesmentnMarkList(action) {
    try {
        const response = yield call(riskassementMarkList, action.id, action.payload);
        if (response.success === true) {
            yield put({
                type: RISK_ASSESMENT_LIST_MARK_SUCCESSED,
                data: response
            });
        }
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'Change Mark Successfully',
                feedbackType: 'success',
                module: moduleName,
            },
        });
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'something went wrong',
                feedbackType: 'error',
                module: moduleName,
            },
        });
        yield put({
            type: RISK_ASSESMENT_LIST_MARK_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchRiskAssesmentUpdate(action) {
    try {
        const response = yield call(riskassementupdateListAPI, action.id, action.payload);
        if (response.success === true) {
            yield put({
                type: RISK_ASSESMENT_UPDATE_LIST_SUCCESSED,
                data: response
            });
            yield put({
                type: SET_FEEDBACK_ALERT,
                payload: {
                    feedbackMessage: 'Update Risk Assesment',
                    feedbackType: 'success',
                    module: moduleName,
                },
            });
        }
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'Something Went Wrong',
                feedbackType: 'error',
                module: moduleName,
            },
        });
        yield put({
            type: RISK_ASSESMENT_UPDATE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchTemplateList(action) {
    try {
        const response = yield call(templateListRiskAssessmentAPI, action.id, action.payload);
        if (response.success === true) {
            yield put({
                type: RISK_ASSESMENT_TEMPLATE_LIST_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_TEMPLATE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchGenerateReports(action) {
    try {
        const response = yield call(generateReportRiskAssessmentAPI, action.id, action.payload);
        if (response.success === true) {
            yield put({
                type: RISK_ASSESMENT_GENERATE_REPORTS_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_GENERATE_REPORTS_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchRiskImport(action) {
    try {
        const response = yield call(importReportRiskAssessmentAPI, action.id, action.payload);
        if (response.success === true) {
            yield put({
                type: RISK_ASSESMENT_GENERATE_REPORTS_SUCCESSED,
                data: response
            });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_GENERATE_REPORTS_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchPDFExport(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(exportPDF, action?.payload);
        if (response.success === true) {
            yield put({ type: RISK_ASSESMENT_EXPORT_PDF_SUCCESSED, data: response?.data });
        }
        yield put({ type: HIDE_LOADER });
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'PDF downloaded.',
                feedbackType: 'success',
                module: moduleName,
            },
        });
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'Something Went Wrong',
                feedbackType: 'error',
                module: moduleName,
            },
        });
        yield put({
            type: RISK_ASSESMENT_EXPORT_PDF_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchExcelExport(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(exportExcel, action.payload);
        if (response.success === true) {
            yield put({ type: RISK_ASSESMENT_EXPORT_EXEL_SUCCESSED, data: response?.data });
            yield put({
                type: SET_FEEDBACK_ALERT,
                payload: {
                    feedbackMessage: 'Excel downloaded.',
                    feedbackType: 'success',
                    module: moduleName,
                },
            });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: 'something went wrong',
                feedbackType: 'error',
                module: moduleName,
            },
        });
        yield put({
            type: RISK_ASSESMENT_EXPORT_EXEL_FAILED,
            data: err?.response?.data?.data,
        });
        yield put({ type: HIDE_LOADER });
    }
}
export function* watchInitialList(action) {
    try {
        const response = yield call(initiatorAPI, action.payload);
        if (response.success === true) {
            yield put({ type: RISK_ASSESMENT_INITIATOR_LIST_SUCCESSED, data: response?.data });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_INITIATOR_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export function* watchScope(action) {
    try {
        const response = yield call(initiatorAPI, action.payload);
        if (response.success === true) {
            yield put({ type: RISK_ASSESMENT_INITIATOR_LIST_SUCCESSED, data: response?.data });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_INITIATOR_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchRiskAssessmentScope(action) {
    try {
        const response = yield call(riskAssessmentScopeApi, action.payload);
        if (response.success === true) {
            yield put({ type: RISK_ASSESMENT_SCOPE_LIST_SUCCESSED, data: response?.data });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_SCOPE_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}
export function* watchRiskRegister(action) {
    try {
        const response = yield call(riskAssessmentScopeApi, action.payload);
        if (response.success === true) {
            yield put({ type: RISK_ASSESMENT_RISK_REGISTER_LIST_SUCCESSED, data: response?.data });
        }
    } catch (err) {
        yield put({
            type: RISK_ASSESMENT_RISK_REGISTER_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export default function* watcher() {
    yield takeLatest(RISK_ASSESMENT_GET_LIST_REQUESTED, watchRiskAssesmentList)
    yield takeLatest(RISK_ASSESMENT_EXPORT_PDF_REQUESTED, watchPDFExport)
    yield takeLatest(RISK_ASSESMENT_EXPORT_EXEL_REQUESTED, watchExcelExport)
    yield takeLatest(RISK_ASSESMENT_SCOPE_LIST_REQUESTED, watchRiskAssessmentScope)
    yield takeLatest(RISK_ASSESMENT_RISK_REGISTER_LIST_REQUESTED, watchRiskRegister)
    yield takeLatest(RISK_ASSESMENT_DELETE_LIST_REQUESTED, watchRiskAssesmentDelete)
    yield takeLatest(RISK_ASSESMENT_LIST_MARK_REQUESTED, watchRiskAssesmentnMarkList)
    yield takeLatest(RISK_ASSESMENT_UPDATE_LIST_REQUESTED, watchRiskAssesmentUpdate)
    yield takeLatest(RISK_ASSESMENT_GENERATE_REPORTS_REQUESTED, watchGenerateReports)
    yield takeLatest(RISK_ASSESMENT_TEMPLATE_LIST_REQUESTED, watchTemplateList)
    yield takeLatest(RISK_ASSESMENT_TEMPLATE_LIST_REQUESTED, watchRiskImport)
}
